/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Table } from 'react-bulma-components'
import styled from 'styled-components'

type TOnChange = (owners: string[]) => void

function OwnersTable({
  onChange,
  initialValues = [],
}: {
  onChange: TOnChange,
  initialValues: string[]
}) {
  const [owner, setOwner] = useState<string>('')
  const [owners, setOwners] = useState<string[]>(initialValues)

  const addOwner = useCallback((e: Event) => {
    e.preventDefault()
    if (owner.length > 0) {
      setOwners([...owners, owner])
      setOwner('')
    }
  }, [owner, owners])

  const removeOwner = useCallback((e: Event, owner: string) => {
    e.preventDefault()
    const tempOnwers = owners.filter((currentOwner) => currentOwner !== owner)
    setOwners([...tempOnwers])
  }, [owners])

  useEffect(() => {
    onChange(owners)
  }, [owners])

  return (
    <Table size="fullwidth" bordered hoverable>
      <thead>
        <tr>
          <th>Hub Username</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {
          owners.map((owner, i) => (
            <tr key={`${i + owner}`}>
              <td>{owner}</td>
              <StyledTd>
                <IconActionButton color="danger" onClick={(e: Event) => removeOwner(e, owner)}>
                  <FontAwesomeIcon icon={faTrash} />
                </IconActionButton>
              </StyledTd>
            </tr>
          ))
        }
        <tr>
          <td>
            <Form.Field>
              <Form.Control fullwidth={false}>
                <Form.Input
                  value={owner}
                  style={{ width: 'unset' }}
                  size="small"
                  name="owner"
                  onChange={(e) => setOwner(e.currentTarget.value)}
                />
              </Form.Control>
            </Form.Field>
          </td>
          <StyledTd>
            <IconActionButton color="success" onClick={addOwner}>
              <FontAwesomeIcon icon={faPlus} />
            </IconActionButton>
          </StyledTd>
        </tr>
      </tbody>
    </Table>
  )
}

export default OwnersTable

const IconActionButton = styled(Button)`
    height: 0;
    padding: 10px 5px;
    font-size: 12px;
    color: #fff;
`

const StyledTd = styled.td`
    text-align: right !important;
`
