/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import React from 'react'
import { Button } from 'react-bulma-components'
import styled from 'styled-components'
import { IButtonGroupCell, ITextCell, TTableCell } from '../types'

interface IProps {
    cellData: TTableCell;
    cellIndex: number;
}

function TableCell({ cellData, cellIndex }: IProps) {
  const { cellType } = cellData

  switch (cellType) {
    case 'buttonGroup':

      return (
        <td>
          <StyledActions key={cellIndex}>
            {(cellData as IButtonGroupCell).buttonData.map((buttonInfo, buttonIndex) => {
              const { color, text, onClick } = buttonInfo

              return (
                <Button color={color} key={buttonIndex} onClick={onClick}>
                  {text}
                </Button>
              )
            })}
          </StyledActions>
        </td>
      )
    default:
      return (
        <td key={cellIndex}>
          <CellContainer>
            <span>{(cellData as ITextCell).text}</span>
          </CellContainer>
        </td>
      )
  }
}

export default TableCell

const StyledActions = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;
    button{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    button:not(:last-child) {
        margin-right: 10px;
    }
`

const CellContainer = styled.div`
    width: 100%;
    display: inline-flex;
    span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
