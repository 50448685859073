import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Columns, Container, Notification } from 'react-bulma-components'
import { Link, useParams } from 'react-router-dom'
import { DetailsTable } from '../../components'
import ROUTES from '../../constants/ROUTES'
import { useFetchServerById } from '../../hooks/api/server'
import { AppLayout } from '../../layouts'

interface INavigationParams {
    serviceID: string
}

function ServerDetailsView() {
  const { serviceID } = useParams<keyof INavigationParams>()

  const { data: serverResponse, isError, isFetching: isLoading } = useFetchServerById(serviceID)
  const server = serverResponse?.data

  const data = useMemo(() => {
    const details = [
      { name: 'ID', value: server?._id ?? '' },
      { name: 'HOST', value: server?.host ?? '' },
      { name: 'Datacenter Name', value: server?.datacenterName ?? '' },
      { name: 'Provider Name', value: server?.providerName ?? '' },
      { name: 'Name', value: server?.name ?? '' },
      { name: 'Description', value: server?.description ?? '' },
      // eslint-disable-next-line no-nested-ternary
      { name: 'State', value: server?.state != null ? server.state ? 'Active' : 'Inactive' : '' },
      { name: 'Disk Capacity (In GB)', value: (server?.capacity != null) ? server.capacity.toString() : '' },
      { name: 'Version', value: server?.databaseVersion ?? '' },
      { name: 'Custom Connection String', value: server?.customConnectionString ?? '' },
      { name: 'Custom Client Host', value: server?.customClientHost ?? '' },
      { name: 'Custom Configuration', value: '' },
      { name: 'Created By', value: server?.createdBy ?? '' },
      { name: 'Created At', value: server?.createdAt ?? '' },
      { name: 'Updated At', value: server?.updatedAt ?? '' },
    ]
    return details
  }, [server])

  return (
    <AppLayout isLoading={isLoading}>
      <Container className="is-fluid">
        <Columns multiline={false} className="mt-5">
          <Columns.Column>
            <h4 className="title is-4">
              <Link to={ROUTES.listServers} replace>
                <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
              </Link>
              <FontAwesomeIcon className="pr-3" icon={faDatabase} />
              Servers &gt; View Server
              {' '}
              {server?.name != null ? `: ${server?.name}` : ''}
            </h4>
            {isError && (
              <Notification color="danger">
                Failed to fatch server information. Please contact support.
              </Notification>
            )}
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <DetailsTable data={data} />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default ServerDetailsView
