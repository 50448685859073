import React, { useMemo } from 'react'
import { Columns, Container } from 'react-bulma-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Table } from '../../components'
import { AppLayout } from '../../layouts'
import { IButtonGroupCell, ITextCell } from '../../types'
import { useFetchEvents } from '../../hooks/api/events'

function ListEvents() {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const { data: eventsResponse, isFetching: isLoading } = useFetchEvents()

  const tableHeaderTitles = [
    { title: 'Owners' },
    { title: 'Event Type' },
    { title: 'Database Name' },
    { title: 'User', width: '12%' },
    { title: 'Taxonomy Id', width: 123 },
    { title: 'Database ID' },
    { title: 'View', width: 111 },
  ]

  const tableRowData = useMemo(() => {
    const rowData = eventsResponse?.data ?? []

    const formatedData: Array<(IButtonGroupCell | ITextCell)[]> = rowData.map((d) => [
      { cellType: 'text', text: d.owners },
      { cellType: 'text', text: d.eventType },
      { cellType: 'text', text: d.databaseName },
      { cellType: 'text', text: d.createdBy },
      { cellType: 'text', text: d.taxonomyId.toString() },
      { cellType: 'text', text: d.dbaasDatabase },
      {
        cellType: 'buttonGroup',
        buttonData: [
          { color: 'success', text: 'View', onClick: () => { navigate(`${pathname}/${d._id}/view`) } },
        ],
      },
    ])

    return formatedData
  }, [eventsResponse])

  const deadColumnHeaders: string[] = ['View']

  return (
    <AppLayout isLoading={isLoading}>
      <Container className="is-fluid">
        <Columns>
          <Columns.Column>
            <Table
              headers={tableHeaderTitles}
              rows={tableRowData}
              deadColumnHeaders={deadColumnHeaders}
            />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default ListEvents
