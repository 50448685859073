import { useMutation, useQueryClient } from 'react-query'
import { useDbaasClient } from '../../client'

interface ICreateDatabaseBody {
    databaseName: string,
    adminUsername: string,
    serverId: string,
    taxonomyId: string,
    size: number,
    password: string,
    owners: string[]
}

const useCreateDatabase = () => {
  const { post } = useDbaasClient()

  const queryClient = useQueryClient()

  return useMutation((body: ICreateDatabaseBody) => post('/databases', body), {
    onSuccess: () => {
      queryClient.invalidateQueries('databases')
    },
  })
}

export default useCreateDatabase
