import { useQuery } from 'react-query'
import IServiceLog from '../../../types/serviceLog'
import { useDbaasClient } from '../../client'

const useFetchServiceLogsByName = (name: string) => {
  const { get } = useDbaasClient()

  return useQuery(['servicelog', name], () => get<IServiceLog[]>(`/servicelog/${name}`), {
    enabled: name.length > 0,
  })
}

export default useFetchServiceLogsByName
