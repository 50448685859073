/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useDbaasClient } from '../../client'

const useFetchProviders = (
  dataCenter: string,
  options?: { onSuccess?: ((data: AxiosResponse<string[], any>) => void) | undefined },
) => {
  const { get } = useDbaasClient()

  return useQuery(['datacenters', dataCenter, 'providers'], () => get<string[]>(`/datacenters/${dataCenter}/providers`), {
    onSuccess: options?.onSuccess,
    enabled: dataCenter.length > 0,
  })
}

export default useFetchProviders
