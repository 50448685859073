/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import React from 'react'
import { Table } from 'react-bulma-components'
import styled from 'styled-components'

interface IDataType {
  name: string,
  value: string
}
interface IProps {
  data: IDataType[]
}

function DetailsTable({ data }: IProps) {
  return (
    <Table bordered hoverable size="fullwidth">
      <colgroup>
        <col style={{ width: '25%' }} />
      </colgroup>
      <tbody>
        {
          data.map((value, index) => (
            <tr key={index}>
              <td>
                <CellContainer>
                  <strong>
                    {' '}
                    {value.name}
                    {' '}
                  </strong>
                </CellContainer>
              </td>
              <td><CellContainer>{value.value}</CellContainer></td>
            </tr>
          ))
        }

      </tbody>
    </Table>
  )
}
const CellContainer = styled.div`
    width: 100%;
    display: inline-flex;
    span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
export default DetailsTable
