import React from 'react'

interface ICredentialContext {
    accessToken?: string,
}

const CredentialContext = React.createContext<ICredentialContext>({
  accessToken: undefined,
})

export default CredentialContext
