/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import ITaxonomyDetails from '../../../types/taxonomy'
import { useTaxonomyClient } from '../../client'

type IOptions = Omit<UseQueryOptions<AxiosResponse<ITaxonomyDetails, any>, unknown, AxiosResponse<ITaxonomyDetails, any>, (string | undefined)[]>, 'queryKey' | 'queryFn'> | undefined

const useFetchTaxonomyDetailsById = (taxonomyId?: string, options?: IOptions) => {
  const { get } = useTaxonomyClient()

  return useQuery(['detail', taxonomyId], () => get<ITaxonomyDetails>('/detail', { params: { id: taxonomyId } }), options)
}

export default useFetchTaxonomyDetailsById
