import { useMutation, useQueryClient } from 'react-query'
import { useDbaasClient } from '../../client'

const useDeleteServerById = () => {
  const client = useDbaasClient()
  const queryClient = useQueryClient()

  return useMutation((serverId: string) => client.delete(`/servers/${serverId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('servers')
    },
  })
}

export default useDeleteServerById
