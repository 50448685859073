const openIdConfig = {
  authorization: 'https://idp.myid.disney.com/as/authorization.oauth2',
  clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  nonce: 0,
  redirectUri: '/myid-session-redirect',
  responseType: 'token id_token',
  revocation: 'https://login.myid.disney.com/logout',
  scope: 'openid profile email relationship'
}

const dbaasApiConfig = {
  endpoint: process.env.REACT_APP_DB_AAS_ENDPOINT ?? "",
  endpointSuffix: "/dbaas/v1",
  taxonomyEndpointSuffix: "/taxonomies"
}

const NODE_ENV = process.env.NODE_ENV

export { openIdConfig, dbaasApiConfig, NODE_ENV }

/*
angular codebase reference
==========
dev
var config = {
  debug                        : true,
  env                          : 'dev',
  csfAuthEndpoint              : 'authorization/v1/',
  dbaasV2Endpoint              : 'https://dbaasapidev.corp.dig.com',
  dbaasV2URLSuffix             : '/dbaas/v1/',
  taxonomyEndpoint             : 'https://taxonomy.prod.pcm.go.com/taxonomy/v1/',
  sessionServer                : 'https://stg.sessions.corp.dig.com',
  wordPressUrl                 : 'https://wordpressdev.lighthouse.corp.dig.com/wordpress/wp-json/wp/v2',
  wordPressAuth                : 'Basic aHNwY21zYWRtaW46V2VsY29tZTAxKg==',


  emailAddresses: {
    HEAT: 'DTSS.DL-HEATANALYTICS@disney.com'
  },

  feedbackEmail: 'EnterpriseTech.DL-CloudProductFeedback@disney.com',

  featureFlags: {
    dbaasFeature: true,
    analyticsDisplayUpdatedDashboard: true
  },

  openId: {
    authorization: 'https://idp.myid.disney.com/as/authorization.oauth2',
    clientId: 'hosting-services-qa',
    nonce: 0,
    redirectUri: '/session-redirect.html',
    responseType: 'token id_token',
    revocation: 'https://login.myid.disney.com/logout',
    scope: 'openid profile email relationship'
  }

========
prod
var config = {
    debug                        : false,
    env                          : 'prod',
    dbaasV2Endpoint              : 'https://dbaasapi.corp.dig.com',
    dbaasV2URLSuffix             : '/dbaas/v1/',
    taxonomyEndpoint             : '//taxonomy.prod.pcm.go.com/taxonomy/v1/',
    sessionServer                : 'https://sessions.corp.dig.com',
    wordPressUrl                 : 'https://wordpress.lighthouse.corp.dig.com/wordpress/wp-json/wp/v2',
    wordPressAuth                : 'Basic aHNwY21zYWRtaW46V2VsY29tZTAxKg==',
  
  
    emailAddresses: {
      HEAT: 'DTSS.DL-HEATANALYTICS@disney.com'
    },
  
    feedbackEmail: 'EnterpriseTech.DL-CloudProductFeedback@disney.com',
  
    featureFlags: {
      dbaasFeature: true,
      analyticsDisplayUpdatedDashboard: true
    },
  
    openId: {
      authorization: 'https://idp.myid.disney.com/as/authorization.oauth2',
      clientId: 'hosting-services',
      nonce: 0,
      redirectUri: '/session-redirect.html',
      responseType: 'token id_token',
      revocation: 'https://login.myid.disney.com/logout',
      scope: 'openid profile email relationship'
    }
  };
  */