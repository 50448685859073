import classNames from 'classnames'
import React from 'react'
import { Button, Form } from 'react-bulma-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Label, Panel, PanelBody } from '../..'
import MaintenanceWindow from './MaintenanceWindow'
import { IOracleFormFields } from '../../../types/createDatabase'

const schema = yup.object({
  capacity: yup.number().typeError('Must be an integer.').positive().required('is required')
    .min(1)
    .max(10, 'Must be no more than 10 GBs.'),
  databaseName: yup.string().required('is required')
    .matches(/^[a-zA-Z0-9_]+$/, { message: 'Must only contain alphanumeric characters. Underscore is also allowed' })
    .min(1)
    .max(64, 'Must be shorter than 64 characters'),
  backupWindow: yup.number().typeError('Must be an integer.').positive().required('is required')
    .min(7)
    .max(35, 'Must be no more than 35 Days.'),
})

function CreateOracleDatabaseForm() {
  const navigation = useNavigate()

  const { formState: { errors }, register } = useForm<IOracleFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      backupWindow: 7,
    },
  })

  return (
    <>
      <Form.Field>
        <Label label="Database Name" required for="databaseName" />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.databaseName != null })} {...register('databaseName')} id="databaseName" />
        </Form.Control>
        {errors.databaseName && <Form.Help color="danger">{errors.databaseName.message}</Form.Help>}
      </Form.Field>

      <Form.Field>
        <Label label="Size" required />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.capacity != null })} {...register('capacity')} id="capacity" />
        </Form.Control>
        <Form.Help color="danger">{errors.capacity != null ? errors.capacity.message : ''}</Form.Help>
      </Form.Field>

      <Panel className="mt-5" titleClassName="" title="Identifier">
        <PanelBody>
          <Form.Control className="mb-2">
            <Form.Radio value="Production" checked>Production</Form.Radio>
          </Form.Control>
          <Form.Control>
            <Form.Radio value="Non-Production" checked={false}>Non-Production</Form.Radio>
          </Form.Control>
        </PanelBody>
      </Panel>
      <Form.Field>
        <Label label="Timezone" />
        <Form.Control>
          <Form.Select id="timezone" fullwidth defaultValue="UTC">
            <option value="PST">PST</option>
            <option value="EST">EST</option>
            <option value="any">ANY</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Label label="License Model" />
        <Form.Control>
          <Form.Select id="license" fullwidth defaultValue="license-included">
            <option value="license-included">License Included</option>
            <option value="BYOL">BYOL (Bring your Own License)</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Label label="Backup Window " />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.backupWindow != null })} {...register('backupWindow')} id="backupWindow" />
        </Form.Control>
      </Form.Field>
      <Panel className="mt-5" titleClassName="" title="Maintenance Window">
        <PanelBody>
          <MaintenanceWindow onChange={() => { /* */ }} />
        </PanelBody>
      </Panel>
      <Form.Field className="is-flex is-justify-content-space-between mt-5">
        <Button color="danger" onClick={() => { navigation(-1) }}>Cancel</Button>
        <Button disabled={false} color="success" type="submit" onClick={() => { /**/ }}>Submit</Button>
      </Form.Field>
    </>
  )
}

export default CreateOracleDatabaseForm
