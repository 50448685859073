/* eslint-disable no-continue */
/* eslint-disable max-len */
import { ITextCell, TTableCell } from '../../types'

function filterRows(rows: TTableCell[][], columnSearchText: { [key: string]: string }) {
  return rows.filter((row) => {
    let thisRowPassesFilter = true
    for (let i = 0; i < row.length; i++) {
      const targetCell = row[i]
      const { cellType } = targetCell
      if (cellType !== 'text') {
        continue
      }

      const matchingColumnFilter = columnSearchText[i]
      const noFilterIsSet = typeof matchingColumnFilter === 'undefined' || matchingColumnFilter === ''
      if (noFilterIsSet) {
        continue
      }

      // a text filter is present
      const { text: cellText } = targetCell as ITextCell
      const filterText = matchingColumnFilter
      const substringMatchIsFound = Boolean(cellText.toLowerCase().includes(filterText.toLowerCase()))

      if (substringMatchIsFound) {
        continue
      }

      thisRowPassesFilter = false
    }

    return thisRowPassesFilter
  })
}

export default filterRows
