import { useMutation, useQueryClient } from 'react-query'
import { useDbaasClient } from '../../client'

const useDeleteDatabaseById = () => {
  const client = useDbaasClient()
  const queryClient = useQueryClient()

  return useMutation((databaseID: string) => client.delete(`/databases/${databaseID}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('databases')
    },
  })
}

export default useDeleteDatabaseById
