import moment from 'moment'
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { Form } from 'react-bulma-components'
import { Label } from '../..'

interface Props {
  onChange: (value: string) => void
}

function MaintenanceWindow({ onChange }:Props) {
  const hours = Array.from(Array(24).keys())
  const minutes = Array.from(Array(60).keys())
  const durations = Array.from(Array(48), (_a, index) => index / 2)
  durations.shift()
  const days = useMemo(() => {
    const data = []
    const firstDay = moment().day('monday').hour(0).minute(0)
      .second(0)
    for (let i = 0; i < 7; i++) {
      data.push({ name: firstDay.format('dddd'), value: firstDay.format('ddd') })
      firstDay.add('days', 1)
    }
    return data
  }, [])

  const [selectedHour, setSelectedHour] = useState('00')
  const [selectedMinute, setSelectedMinute] = useState('00')
  const [selectedDuration, setSelectedDuration] = useState('0.5')

  const onChangeRef = useRef(onChange)

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useEffect(() => {
    // const endTime = moment().hours(Number(selectedHour))
    // .minutes(Number(selectedMinute)).add('hours, .5')
    // const value = `${selectedHour}:${selectedMinute}-`
    //   onChange()
  }, [selectedDuration, selectedHour, selectedMinute])

  return (
    <>
      <Form.Field>
        <Label label="Start Day" />
        <Form.Control>
          <Form.Select id="startday" fullwidth defaultValue="Mon">
            {days.map((day) => <option key={day.name} value={day.value}>{day.name}</option>)}
          </Form.Select>
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Label label="Start time" />
        <div className="field-body">
          <Form.Field>
            <Form.Control>
              <Form.Select
                id="starttime"
                fullwidth
                defaultValue="00"
                value={selectedHour}
                onChange={({ target }) => { setSelectedHour(target.value) }}
              >
                {hours.map((count) => {
                  const value = count < 10 ? `0${count}` : `${count}`
                  return <option key={`hourkey${value}`} value={value}>{value}</option>
                })}
              </Form.Select>
            </Form.Control>
          </Form.Field>
          <Form.Label><h1 className="title h3">:</h1></Form.Label>
          <Form.Field className="ml-3">
            <Form.Control>
              <Form.Select
                id="minutes"
                fullwidth
                defaultValue="00"
                value={selectedMinute}
                onChange={({ target }) => { setSelectedMinute(target.value) }}
              >
                {minutes.map((count) => {
                  const value = count < 10 ? `0${count}` : `${count}`
                  return <option key={`hourkey${value}`} value={value}>{value}</option>
                })}
              </Form.Select>
            </Form.Control>
          </Form.Field>
        </div>
      </Form.Field>
      <Form.Field>
        <Label label="Duration" />
        <Form.Field>
          <Form.Control>
            <Form.Select
              id="duration"
              fullwidth
              defaultValue="0.5"
              value={selectedDuration}
              onChange={({ target }) => { setSelectedDuration(target.value) }}
            >
              {durations.map((count) => {
                const value = String(count)
                return <option key={`hourkey${value}`} value={value}>{value}</option>
              })}
            </Form.Select>
          </Form.Control>
        </Form.Field>
      </Form.Field>
    </>
  )
}

export default MaintenanceWindow
