import styled from 'styled-components'

const AdminContainer = styled.div`
    max-width: 95vw;
    width: 100%;
    margin: 0px auto;
    margin-top: 27px;
`

const AdminHead = styled.div`
    display: block;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 100%;
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;

    h1 {
        font-size: 16px;
        font-weight: 500;
    }
`

const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 16px 20px;
    height: 100%;

    ul {
        height: 100%;
        li {
            &:hover {
                background-color: #eee;
            }
            a {
                display: block;
                padding: 15px 40px;
                color: #000;
                &:hover {
                    color: #000;
                }
            }
        }
    }
`
export {
  AdminContainer, AdminHead, Sidebar,
}
