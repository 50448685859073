import React from 'react'
import { Columns } from 'react-bulma-components'
import { Link } from 'react-router-dom'
import { AppLoader } from '../components'
import ROUTES from '../constants/ROUTES'
import { useAuth } from '../providers/AuthProvider'
import {
  AdminContainer, AdminHead, Sidebar,
} from './AppLayoutStyles'

function AppLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {
  const { isAdmin, isLoading: isAuthLoading, isAuthenticated } = useAuth()
  const loading = (isLoading ?? false) || isAuthLoading

  return (
    <>
      <AppLoader isLoading={loading} />
      {isAuthenticated
          && (
            <AdminContainer>
              <AdminHead>
                <h1>
                  DBAAS
                  {' '}
                  {isAdmin && <>Admin</>}
                </h1>
              </AdminHead>
              <Columns multiline={false} display="flex" flexWrap="nowrap" justifyContent="center">
                {isAdmin
                  && (
                    <Columns.Column size={2}>
                      <Sidebar data-testid="sidebar">
                        <ul>
                          <li>
                            <Link to={ROUTES.listServers}>Servers</Link>
                          </li>
                          <li>
                            <Link to={ROUTES.listDatabases}>Databases</Link>
                          </li>
                          <li>
                            <Link to={ROUTES.listAdministrator}>Adminstrator</Link>
                          </li>
                          <li>
                            <Link to={ROUTES.listEvents}>Events</Link>
                          </li>
                          <li>
                            <Link to={ROUTES.listLogs}>Logs</Link>
                          </li>
                        </ul>
                      </Sidebar>
                    </Columns.Column>
                  )}
                <Columns.Column size={10}>{children}</Columns.Column>
              </Columns>
            </AdminContainer>
          )}
    </>
  )
}

AppLayout.defaultProps = {
  isLoading: false,
}

export default AppLayout
