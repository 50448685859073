import axios, { AxiosError, AxiosInstance } from 'axios'
import React, { useEffect, useMemo } from 'react'
import { dbaasBaseEndpoint, taxonomyBaseEndPoint } from '../../config/api'
import useAccessToken from '../../hooks/auth/useAccessToken'
import { useAuthUpdate } from '../AuthProvider'
import DbaasClientContext from './DbaasClientContext'

function DbaasClientProvider({ children }: { children: React.ReactNode }) {
  const { updateAuthOneValue } = useAuthUpdate()
  const accessToken = useAccessToken()

  const clients = useMemo(() => {
    const dbaasAxiosClient = axios.create({
      baseURL: dbaasBaseEndpoint,
    })

    const taxonomyAxiosClient = axios.create({
      baseURL: taxonomyBaseEndPoint,
    })

    return { dbaasClient: dbaasAxiosClient, taxonomyClient: taxonomyAxiosClient }
  }, [])

  const { dbaasClient, taxonomyClient } = clients

  useEffect(() => {
    const addRequestInterceptor = (axiosInctance: AxiosInstance) => (
      axiosInctance.interceptors.request.use((config) => {
        if (config.headers !== undefined && !('Authorization' in config.headers)) {
          const token = accessToken
          if (token != null && token?.length > 0) {
            // eslint-disable-next-line no-param-reassign
            config.headers = { ...config.headers, Authorization: `Bearer ${token}` }
          }
        }
        return config
      }))

    const addResponseInterceptor = (axiosInctance: AxiosInstance) => (
      axiosInctance.interceptors.response.use(
        (config) => config,
        (error: AxiosError) => {
          if (error.message === 'Network Error'
                    || (error.config.url === '/isadmin' && error.response?.status !== undefined && error.response?.status >= 500)
                    // experienced 404 errror without this line. re-check if still needed
                    || (error.config.url === '/isadmin' && error.response?.status === 404)) {
          // application should show global error modal
            updateAuthOneValue('hasError', true)
          }
          return Promise.reject(error)
        },
      ))

    const dbaasRequestInterceptor = addRequestInterceptor(dbaasClient)
    const dbaasResponseInterceptor = addResponseInterceptor(dbaasClient)

    const taxonomyRequestInterceptor = addRequestInterceptor(taxonomyClient)
    const taxonomyResponseInterceptor = addResponseInterceptor(taxonomyClient)

    return () => {
      /* cleanup */
      dbaasClient.interceptors.request.eject(dbaasRequestInterceptor)
      dbaasClient.interceptors.response.eject(dbaasResponseInterceptor)
      taxonomyClient.interceptors.request.eject(taxonomyRequestInterceptor)
      taxonomyClient.interceptors.response.eject(taxonomyResponseInterceptor)
    }
  }, [dbaasClient, taxonomyClient, accessToken])

  return (
    <DbaasClientContext.Provider value={clients}>
      {children}
    </DbaasClientContext.Provider>
  )
}

export default DbaasClientProvider
