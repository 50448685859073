/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import '../styles/DbaasTable.module.css'
import TTableCell from '../types/TTableCell'
import TableRow from './TableRow'

interface IProps {
    tableRowData: TTableCell[][],
    columnSearchText: { [key: string]: string }
}

function TableBodyRowGroup({ tableRowData }: IProps) {
  return (
    <>
      {tableRowData?.map((singleRowData, rowIndex) => (
        <TableRow
          singleRowData={singleRowData}
          rowIndex={rowIndex}
          key={rowIndex}
        />
      ))}
    </>
  )
}

export default TableBodyRowGroup
