/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  faBan, faCaretLeft, faDatabase, faEye, faPencil, faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Button, Container, Form, Notification,
} from 'react-bulma-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { Panel, PanelBody } from '../../components'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import ROUTES from '../../constants/ROUTES'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { useCreateDatabase } from '../../hooks/api/databases'
import { AppLayout } from '../../layouts'
import { useAuth } from '../../providers/AuthProvider'
import ICreateDataBaseFormData from '../../types/forms/database'
import WithKeyOptional from '../../types/utils'

interface BaseForm extends WithKeyOptional<ICreateDataBaseFormData> {
  taxonomyId: string | undefined
}

function CreateDatabaseConfirm() {
  const { isAuthenticated } = useAuth()
  useFetchIsAdmin({ enabled: !isAuthenticated })

  const [isPasswordHidden, setisPasswordHidden] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const formState = useMemo(() => location.state as BaseForm | undefined, [location])

  const password = useMemo(() => {
    if ((formState?.password) != null && (Boolean(formState.password.length))) {
      if (isPasswordHidden) {
        let hiddenPass = ''
        for (let index = 0; index < formState.password.length; index++) {
          hiddenPass = `${hiddenPass}*`
        }
        return hiddenPass
      }
      return formState.password
    }
    return undefined
  }, [formState?.password, isPasswordHidden])

  const isValidState = useMemo(() => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*';\-%])[A-Za-z\d\\@&^#$!`*';\-%]{10,128}$/
    if (
      ((formState?.capacity) != null) && !Number.isNaN(formState?.capacity)
      && Number(formState?.capacity) > 0
      && ((formState?.taxonomyId) != null && formState?.taxonomyId.length > 0)
      && ((formState?.dataCenter) != null && ((formState?.dataCenter?.length) !== 0))
      && ((formState?.providerName) != null && ['MSSQL', 'MYSQL', 'PostgreSQL'].some((provider) => formState.providerName === provider))
      && ((formState?.password) != null && passwordPattern.test(formState.password))
      && ((formState?.serviceOffering) != null && formState.serviceOffering.length > 10)
    ) {
      return true
    }
    return false
  }, [formState])

  const { mutate, isError, isLoading } = useCreateDatabase()

  useEffect(() => {
    if (isError || !isValidState) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [isError, isLoading, isValidState])

  const onClickCreateDatabase = () => {
    mutate({
      adminUsername: formState?.user as string,
      databaseName: formState?.databaseName as string,
      owners: formState?.owners as string[],
      password: formState?.password as string,
      serverId: formState?.serviceOffering as string,
      size: Number(formState?.capacity) as number,
      taxonomyId: formState?.taxonomyId as string,
    }, {
      onError: () => {
        setIsModalOpen(false)
      },
      onSuccess: () => {
        setIsModalOpen(false)
        bulmaToast.toast({
          message: `<b>${formState?.databaseName}</b> Database is successfully created!.`,
          type: 'is-success',
          dismissible: true,
          duration: 6000,
          position: 'bottom-right',
          animate: { in: 'fadeIn', out: 'fadeOut' },
        })
        navigate(ROUTES.listDatabases, {
          replace: true,
        })
      },
    })
  }

  return (
    <AppLayout isLoading={isLoading}>
      <ConfirmModal
        isReverseActionButtonsPosition
        isOpen={isModalOpen}
        title="Are you sure you want to create ?"
        description="You are about to create the database , you can not undo this action, are you sure you want to continue?"
        okayButton={(
          <Button disabled={isLoading} onClick={onClickCreateDatabase} color="success">
            {' '}
            <FontAwesomeIcon icon={faPlus} className="mr-1" />
            {' '}
            Create Database
          </Button>
        )}
        cancelButton={
          <Button data-testid="modalCancelButton" disabled={isLoading} color="default" onClick={() => { setIsModalOpen(false) }}>Cancel</Button>
        }
      />
      <Container className="is-fluid">
        <h4 className="title is-4">
          <Link to={ROUTES.createDatabase} replace>
            <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
          </Link>
          <FontAwesomeIcon className="pr-3" icon={faDatabase} />
          <span>On Demand Database &gt; Create &gt; Summary</span>
        </h4>
        {showErrorModal && (
          <Notification color="danger">
            {isError && <button data-testid="nCrossBtnId" className="delete" onClick={() => { setShowErrorModal(false) }} />}
            {isError
              ? `Failed to create database '${formState?.databaseName}. Please contact support.`
              : 'Unexpected error occurred!. Check your inputs and try again or contact admin.'}
          </Notification>
        )}
        <Panel title="Summary">
          <PanelBody>
            <p>
              Datacenter:
              {formState?.dataCenter}
            </p>
            <p>
              Provider:
              {formState?.providerName}
            </p>
            <p>
              Business Unit:
              {formState?.taxonomyId}
            </p>
            <p>
              Size:
              {formState?.capacity}
            </p>
            <p>
              Admin Username:
              {formState?.user}
            </p>
            <p>
              Additional Owners:
              {formState?.owners?.map((value, i) => <span key={`${i + value}`}>{`${value} `}</span>)}
            </p>
            <p>
              Password:
              {((formState?.password) != (null)) && (
                <>
                  {password}
                  {' '}
                  &nbsp;
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    icon={faEye}
                    onClick={() => { setisPasswordHidden(!isPasswordHidden) }}
                  />
                </>
              )}
            </p>
            <Panel title="TERMS AND CONDITIONS" className="mt-5">
              <PanelBody>
                In order to proceed with the database creation,
                you must read and accept the terms of service
                <div className="mt-2 is-flex">
                  <Form.Checkbox
                    name="checked"
                    id="checked"
                    checked={isChecked}
                    onChange={({ target }) => { setIsChecked(target.checked) }}
                  />
                  <Form.Label htmlFor="checked"><strong> &nbsp; I read and agree to the terms of service</strong></Form.Label>
                </div>
              </PanelBody>
            </Panel>
            <div className="is-flex mt-5">
              <Link to={ROUTES.listDatabases} replace>
                <Button className="mr-2" color="danger">
                  <FontAwesomeIcon className="pr-1" icon={faBan} />
                  Cancel
                </Button>
              </Link>
              <Link to={ROUTES.createDatabaseEdit} state={formState}>
                <Button className="mr-2" color="info">
                  <FontAwesomeIcon className="pr-1" icon={faPencil} />
                  Edit
                </Button>
              </Link>
              <Button onClick={() => { setIsModalOpen(true) }} disabled={!isChecked || !isValidState} className="ml-auto" color="success">Save</Button>
            </div>
          </PanelBody>
        </Panel>
      </Container>
    </AppLayout>
  )
}

export default CreateDatabaseConfirm
