import { useQuery } from 'react-query'
import { useTaxonomyClient } from '../../client'

interface IBusinessunit {
    _id: number,
    businessName: string,
    businessUnitId: number,
    affiliateId: number,
    subAffiliateId: number
}

const useFetchBusinessUnit = (segment: string, options: {
   onSettled: (() => void) | undefined
  }) => {
  const { get } = useTaxonomyClient()

  return useQuery(['businessunit', segment], () => get<IBusinessunit[]>('/businessunit', {
    params: {
      segment,
    },
  }), {
    enabled: segment.length > 0, onSettled: options.onSettled,
  })
}

export default useFetchBusinessUnit
