/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  Routes as BaseRoutes, Route, Navigate,
} from 'react-router-dom'
import { Container } from 'react-bulma-components'
import { openIdConfig } from './config/config'
import {
  CreateDatabase,
  Home,
  ListDbaas,
  CreateDatabaseConfirm,
  ListServer,
  CreateServerConfiguration,
  CreateAdministrator,
  ListAdministrator,
  ListEvents,
  ListServiceLogs,
  ServerDetailsView,
  ViewDatabaseDetails,
  ViewEventDetails,
  ViewServiceLogDetails,
} from './pages'
import ROUTES from './constants/ROUTES'
import { useAuth } from './providers/AuthProvider'
import { Spacer } from './styles/Home.module'
import Redirect from './pages/redirect/Redirect'

function ProtectedRoute({
  children,
  isAdminProtected,
}: { children: JSX.Element, isAdminProtected?:boolean }) {
  const { hasError, isAdmin, isAuthenticated } = useAuth()

  if (hasError) {
    return (
      <Spacer>
        <Container alignItems="center" display="flex" flexDirection="column">
          <h1 className="title">Error</h1>

          <h4>There was an unspecified error while loading this page.</h4>
          <h4>
            <a href="#" onClick={() => window.location.reload()}>Reload</a>
            {' '}
            or try again later
          </h4>

        </Container>
      </Spacer>
    )
  }

  if (!isAdmin && isAuthenticated && isAdminProtected) {
    return <Navigate to={ROUTES.listDatabases} replace />
  }

  return children
}

ProtectedRoute.defaultProps = {
  isAdminProtected: true,
}

function Routes() {
  return (
    <BaseRoutes>
      <Route path="/" element={<Home />} />
      <Route
        path={ROUTES.databaseCreatedConfirm}
        element={(
          <ProtectedRoute isAdminProtected={false}>
            <CreateDatabaseConfirm />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createDatabase}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <CreateDatabase />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createDatabaseEdit}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <CreateDatabase />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listDatabases}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <ListDbaas />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewDatabseDetails}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <ViewDatabaseDetails />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listServers}
        element={(
          <ProtectedRoute>
            <ListServer />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewServer}
        element={(
          <ProtectedRoute>
            <ServerDetailsView />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createServerConfigure}
        element={(
          <ProtectedRoute>
            <CreateServerConfiguration />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listAdministrator}
        element={(
          <ProtectedRoute>
            <ListAdministrator />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createAdministrator}
        element={(
          <ProtectedRoute>
            <CreateAdministrator />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listEvents}
        element={(
          <ProtectedRoute>
            <ListEvents />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewEventDetails}
        element={(
          <ProtectedRoute>
            <ViewEventDetails />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listLogs}
        element={(
          <ProtectedRoute>
            <ListServiceLogs />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewLogDetails}
        element={(
          <ProtectedRoute>
            <ViewServiceLogDetails />
          </ProtectedRoute>
        )}
      />
      <Route
        path={openIdConfig.redirectUri}
        element={(
          <ProtectedRoute>
            <Redirect />
          </ProtectedRoute>
        )}
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </BaseRoutes>
  )
}

export default Routes
