import { useQuery } from 'react-query'
import { useAuth } from '../../../providers/AuthProvider'
import { useTaxonomyClient } from '../../client'

const useFetchSegment = (options?: { onSettled?: (() => void) }) => {
  const { get } = useTaxonomyClient()
  const { isAuthenticated } = useAuth()

  return useQuery(['segment'], () => get<string[]>('/segment'), {
    onSettled: options?.onSettled,
    enabled: isAuthenticated,
  })
}

export default useFetchSegment
