import { dbaasApiConfig, NODE_ENV, openIdConfig as openId } from "../config";

const clientId = encodeURIComponent(openId.clientId);
const nonce = encodeURIComponent(openId.nonce);
const redirectUri = encodeURIComponent(window.location.origin + openId.redirectUri);
const responseType = encodeURIComponent(openId.responseType);
const scope = encodeURIComponent(openId.scope);

export const authorizationEndpoint = [
    openId.authorization,
    '?client_id=',
    clientId,
    '&nonce=',
    nonce,
    '&redirect_uri=',
    redirectUri,
    '&response_type=',
    responseType,
    '&scope=',
    scope
].join('');

const testBaseURl = `http://mockapi`

const getDbaasBaseUrl = () => {
    if (NODE_ENV === "production") {
        return `${dbaasApiConfig.endpoint}${dbaasApiConfig.endpointSuffix}`
    } else if (NODE_ENV === "development") {
        return `${dbaasApiConfig.endpointSuffix}`
    } else if (NODE_ENV === "test") {
        return `${testBaseURl}${dbaasApiConfig.endpointSuffix}`
    }
}

const getTaxonomyUrl = () => {
    if (NODE_ENV === "production") {
        return `${dbaasApiConfig.endpoint}${dbaasApiConfig.taxonomyEndpointSuffix}`
    } else if (NODE_ENV === "development") {
        return `${dbaasApiConfig.taxonomyEndpointSuffix}`
    } else if (NODE_ENV === "test") {
        return `${testBaseURl}${dbaasApiConfig.taxonomyEndpointSuffix}`
    }
}

export const dbaasBaseEndpoint = getDbaasBaseUrl()
export const taxonomyBaseEndPoint = getTaxonomyUrl()