import { useQuery } from 'react-query'
import IDatabase from '../../../types/databases'
import { useDbaasClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

const useFetchDatabases = () => {
  const { get } = useDbaasClient()
  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  const canFetch = !isLoading && isSuccess
  return useQuery(['databases'], () => get<IDatabase[]>('/databases'), { enabled: canFetch })
}

export default useFetchDatabases
