/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bulma-components'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'

interface ILabel {
    label: string;
    required?: boolean;
    for?: string,
    className?: string,
    style?: React.CSSProperties,
    popover?: React.ReactNode
}

function Label(props: ILabel) {
  const [controlledVisible, setControlledVisible] = useState(false)

  const {
    label, className, for: htmlFor, popover, required, style,
  } = props

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    visible: controlledVisible,
    placement: 'right',
    onVisibleChange: setControlledVisible,
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleKeyDown = ({ key }: any) => {
      if (key === 'Escape') {
        setControlledVisible(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Form.Label htmlFor={htmlFor} style={style} className={classNames('title is-6 mt-5', className)}>
      {label}
      {(required === true)
            && <span style={{ color: '#f14668' }}>&nbsp;*&nbsp;</span>}
      {popover != null && (
        <a href={undefined} ref={setTriggerRef}>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </a>
      )}

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {popover}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </Form.Label>
  )
}

export default Label
