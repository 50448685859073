/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, useRef, useState } from 'react'

export interface IAuth {
    isAuthenticated: boolean,
    isLoading: boolean,
    isAdmin: boolean,
    hasError: boolean,
}

interface IAuthContext extends IAuth {
    updateAuth?: React.Dispatch<React.SetStateAction<IAuth>>
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: false,
  isAdmin: false,
  hasError: true,
})

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [{
    isAdmin, isAuthenticated, isLoading, hasError,
  }, setAuthState] = useState<IAuth>({
    isAdmin: false,
    isLoading: false,
    isAuthenticated: false,
    hasError: false,
  })

  return (
    <AuthContext.Provider value={{
      isLoading,
      isAuthenticated,
      isAdmin,
      hasError,
      updateAuth: setAuthState,
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const {
    isAdmin, hasError, isAuthenticated, isLoading,
  } = useContext(AuthContext)
  return {
    isAdmin, hasError, isAuthenticated, isLoading,
  } as IAuth
}

type K = keyof IAuth
export const useAuthUpdate = () => {
  const { updateAuth, ...rest } = useContext(AuthContext)

  // update one key
  const updateAuthOneValueRef = useRef((key: K, val: IAuth[K]) => {
    const auth: IAuth = { ...rest, [key]: val }
    if (updateAuth !== undefined) {
      updateAuth(auth)
    }
  })

  const updateRef = useRef((auth: IAuth) => {
    if (updateAuth !== undefined) {
      updateAuth(auth)
    }
  })

  return { updateAuth: updateRef.current, updateAuthOneValue: updateAuthOneValueRef.current }
}

export default AuthProvider
