import { useQuery } from 'react-query'
import IAdministrator from '../../../types/admin'
import { useDbaasClient } from '../../client'
import useFetchIsAdmin from './useFetchIsAdmin'

const useFetchAdmins = () => {
  const { get } = useDbaasClient()
  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  const canFetch = !isLoading && isSuccess

  return useQuery(['admins'], () => get<IAdministrator[]>('admins'), { enabled: canFetch })
}

export default useFetchAdmins
