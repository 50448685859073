import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactModal from 'react-modal'
import appLoaderStyle from './AppLoader.module.scss'

function AppLoader({ isLoading }: { isLoading: boolean }) {
  return (
    <ReactModal
      ariaHideApp={false}
      className={appLoaderStyle.modal}
      overlayClassName={appLoaderStyle.overlay}
      isOpen={isLoading}
    >
      <FontAwesomeIcon icon={faCog} size="6x" spin />
    </ReactModal>
  )
}

export default AppLoader
