const basePath = '/dbaasAdmin'
const listDatabases = `${basePath}/databases`
const viewDatabseDetails = `${listDatabases}/:databaseID/view`
const createDatabase = `${basePath}/create`
const createDatabaseEdit = `${createDatabase}/edit`
const databaseCreatedConfirm = `${basePath}/create-form-summary`
const listServers = basePath
const viewServer = `${listServers}/:serviceID/view`
const createServerConfigure = `${listServers}/createServer`
const listAdministrator = `${basePath}/listAdministrator`
const createAdministrator = `${basePath}/createAdministrator`
const listEvents = `${basePath}/events`
const viewEventDetails = `${basePath}/events/:eventId/view`
const listLogs = `${basePath}/listLog`
const viewLogDetails = `${basePath}/viewLog`

const ROUTES = {
  basePath,
  listDatabases,
  createDatabase,
  createDatabaseEdit,
  databaseCreatedConfirm,
  listServers,
  viewServer,
  viewDatabseDetails,
  createServerConfigure,
  listAdministrator,
  createAdministrator,
  listEvents,
  viewEventDetails,
  listLogs,
  viewLogDetails,
}

export default ROUTES
