import React from 'react'
import { TTableCell } from '../types'
import TableCell from './TableCell'

interface IProps {
    singleRowData: TTableCell[];
    rowIndex: number;
}

function TableRow({ singleRowData, rowIndex }: IProps) {
  return (
    <tr key={rowIndex}>
      {
            singleRowData?.map?.((singleCellData: TTableCell, cellIndex: number) => (
              <TableCell
                cellData={singleCellData}
                cellIndex={cellIndex}
                key={`${cellIndex + singleCellData.cellType}`}
              />
            ))
        }
    </tr>
  )
}

export default TableRow
