import React from 'react'
import { Columns, Container } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  BorderBox, RightContent, LinkList, LeftContent, Spacer,
} from '../../styles/Home.module'
import PlainTextLink from '../../styles'
import ROUTES from '../../constants/ROUTES'

function HomePage() {
  return (
    <Spacer>
      <Container>
        <Columns>
          <Columns.Column
            desktop={{
              size: 8,
            }}
            mobile={{
              size: 12,
            }}
          >
            <LeftContent>
              <h1>Welcome</h1>
              <p>
                Here you will find hosting tools for managing your DBaaS application
              </p>

              <BorderBox>
                <h2>On Demand</h2>

                <h3>
                  <PlainTextLink to={ROUTES.listDatabases}>
                    <FontAwesomeIcon icon={faDatabase} />
                    {' '}
                    Database as a Service
                    (DBaaS)
                  </PlainTextLink>
                </h3>

                <p>Offers full self-service provisioning and decommissioning.</p>
              </BorderBox>
            </LeftContent>
          </Columns.Column>
          {/* Ends Left Content */}

          <Columns.Column>
            <RightContent>
              <h2>Bookmarks</h2>

              <LinkList>
                <li>
                  <p> Contact Us </p>
                  <h5>
                    <PlainTextLink to="mailto:DTSS.DL-PCM.Support@disney.com?subject=Support">For Public Cloud Manager Help</PlainTextLink>
                  </h5>
                </li>
                <li>
                  <h5>
                    <PlainTextLink to="mailto:#DTSSIAASProgramTeam@disney.com?subject=Support">For IAAS Help</PlainTextLink>
                  </h5>
                </li>
                <li>
                  <h5>
                    <PlainTextLink to="mailto:DTSS.DBAAS.Program.Team@disney.com?subject=Support">
                      For DBAAS Help
                    </PlainTextLink>
                  </h5>
                </li>
              </LinkList>

              <LinkList>
                <li>
                  <p> Related Links </p>
                  <h5>
                    <PlainTextLink target="_blank" to="https://devcentral.disney.com/display/ODC/On+Demand+Hosting">DevCentral Docs</PlainTextLink>
                  </h5>
                </li>
                <li>
                  <h5>
                    <PlainTextLink target="_blank" to="https://efs.disney.com:9031/idp/startSSO.ping?PartnerSpId=urn:amazon:webservices">SAML Sign-in to AWS</PlainTextLink>
                  </h5>
                </li>
              </LinkList>
            </RightContent>
          </Columns.Column>
          {/* Ends Right COntent */}
        </Columns>
        <Columns justifyContent="center" className="mt-2">
          <div className="text-center">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Questions? Comments? Send e-mail to:
            {' '}
            <PlainTextLink to="mailto:EnterpriseTech.DL-CloudProductFeedback@disney.com?subject=PCM%20Inquiry">
              EnterpriseTech.DL-CloudProductFeedback@disney.com
            </PlainTextLink>
            {' '}

          </div>
        </Columns>
      </Container>
    </Spacer>
  )
}

export default HomePage
