import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { authorizationEndpoint } from '../../../config/api'
import { openIdConfig } from '../../../config/config'
import { useAuthUpdate } from '../../../providers/AuthProvider'
import useAccessToken from '../../auth/useAccessToken'
import { useDbaasClient } from '../../client'

interface Options {
    enabled: boolean
}

const useFetchIsAdmin = (options?: Options) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { pathname } = location
  const isRedirectPath = openIdConfig.redirectUri === pathname

  const { get } = useDbaasClient()

  const accessToken = useAccessToken()

  const query = useQuery(['isAdmin'], () => get('/isadmin', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }), {
    enabled: accessToken != null
    && accessToken.length > 0
    && ((options != null) ? options?.enabled : true),
    retry: false,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
  })

  const {
    isFetching: isLoading, data, isError, error: errorResponse,
  } = query

  const { updateAuthOneValue, updateAuth } = useAuthUpdate()

  useEffect(() => {
    updateAuthOneValue('isLoading', isLoading)
    if (!isLoading) {
      const response = data
      if (typeof response?.data === 'boolean') {
        if (isRedirectPath && accessToken != null) {
          sessionStorage.setItem('access_token', accessToken ?? '')
        }
        updateAuth({
          isAdmin: response?.data,
          // isAdmin: true,
          hasError: false,
          isAuthenticated: true,
          isLoading: false,
        })
        if (isRedirectPath) {
          const tempPath = sessionStorage.getItem('current_path')
          if (tempPath != null && typeof tempPath === 'string') {
            navigate(tempPath, { replace: true })
          } else {
            navigate('/', { replace: true })
          }
        }
      }
    }
  }, [isLoading, data, isRedirectPath])

  useEffect(() => {
    if (isError) {
      const error = errorResponse as AxiosError
      if (error.isAxiosError && ((error.response?.status) !== undefined)
                && error.response?.status < 500 && error.response.status !== 404) {
        if (!isRedirectPath) {
          sessionStorage.setItem('current_path', pathname)
        }
        // redirect
        window.location.href = authorizationEndpoint
      } else {
        updateAuthOneValue('hasError', true)
      }
    }
  }, [isError, errorResponse, isRedirectPath, pathname])

  return query
}

export default useFetchIsAdmin
