import { useMutation, useQueryClient } from 'react-query'
import { useDbaasClient } from '../../client'

const useDeleteAdministratorById = () => {
  const client = useDbaasClient()
  const queryClient = useQueryClient()

  return useMutation((adminId: string) => client.delete(`/admins/${adminId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('admins')
    },
  })
}

export default useDeleteAdministratorById
