import { AxiosResponse } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { useDbaasClient } from '../../client'

interface IStat {
    'statType': 'storage' | string,
    'dbaasDatabase': string,
    'total_size_mb': number | string,
    'row_size_mb': number | string,
    'log_size_mb': number | string,
    'timestamp': string,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TOptions = Omit<UseQueryOptions<AxiosResponse<IStat[], any>, unknown, AxiosResponse<IStat[], any>, (string | undefined)[]>, 'queryKey' | 'queryFn'> | undefined

const useFetchDatabaseStats = (databaseID?: string, options?: TOptions) => {
  const { get } = useDbaasClient()
  return useQuery(['databases', databaseID, 'stats'], () => get<IStat[]>(`/databases/${databaseID}/stats`), options)
}

export default useFetchDatabaseStats
