/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

type TSortInfo = 'ascending' | 'descending' | undefined
interface IProps {
  columnSearchText: { [key: string]: string };
  headerTitle: string;
  hasSearch: boolean;
  columnId: number;
  setColumnSearchText: any;
  width?: string | number;
  onSelectedSort: (sort: TSortInfo) => void;
  isActive: boolean;
}

const StyledTd = styled.td`
    width: auto;
    ${(props) => (props.width != null) && css`
        width: ${Number.isNaN(props.width as any) ? `${props.width}` : `${props.width}px`};
white-space: nowrap;
`}
`

const TitleContainer = styled.div<{hasPointerCursor: boolean}>`
  display: flex;
  align-items: center;
  font-weight: bold;
  ${(props) => props.hasPointerCursor && css`
    cursor: pointer;
    svg {
      margin-left: 10px;
    }
  `}
`

function TableHeader({
  columnSearchText, headerTitle, hasSearch, columnId, setColumnSearchText, width, onSelectedSort,
  isActive,
}: IProps) {
  const [sortType, setSortType] = useState<TSortInfo | undefined>()

  useEffect(() => {
    if (!isActive) {
      setSortType(undefined)
    }
  }, [isActive])

  useEffect(() => {
    if (sortType) { onSelectedSort(sortType) }
  }, [sortType])

  return (
    <StyledTd width={width}>
      <TitleContainer
        hasPointerCursor={hasSearch}
        onClick={() => {
          if (hasSearch) {
            if (sortType == null || sortType === 'descending') {
              setSortType('ascending')
            } else if (sortType === 'ascending') {
              setSortType('descending')
            }
          }
        }}
      >
        {headerTitle}
        {(sortType === 'ascending' && hasSearch) && <FontAwesomeIcon icon={faSortUp} />}
        {(sortType === 'descending' && hasSearch) && <FontAwesomeIcon icon={faSortDown} />}
      </TitleContainer>
      <div>
        {hasSearch ? (
          <input
            type="text"
            style={{ width: '100%' }}
            onChange={((event) => setColumnSearchText({
              ...columnSearchText,
              [columnId]: event.target.value,
            }))}
          />
        ) : null}
      </div>
    </StyledTd>
  )
}

export default TableHeader
