/* eslint-disable no-use-before-define */
import React, { useMemo, useState } from 'react'
import * as bulmaToast from 'bulma-toast'
import { Columns, Button } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlertModal, ConfirmModal, Table } from '../../components'
import { IButtonGroupCell, ITextCell } from '../../types'
import AppLayout from '../../layouts/AppLayout'
import ROUTES from '../../constants/ROUTES'
import useFetchDatabases from '../../hooks/api/databases/useFetchDatabases'
import { useDeleteDatabaseById } from '../../hooks/api/databases'
import IDatabase from '../../types/databases'

function ListDbaas() {
  const navigation = useNavigate()
  const { pathname } = useLocation()

  const [showAlert, setShowAlert] = useState(false)

  const { data, isFetching: isDatabaseLoading } = useFetchDatabases()

  const [selectedDatabase, setSelectedDatabase] = useState<IDatabase | undefined>()

  const { mutate, isLoading: isDatabaseDeleteLoading } = useDeleteDatabaseById()

  const isLoading = isDatabaseLoading || isDatabaseDeleteLoading

  const tableRowData = useMemo(() => {
    const rowData: IDatabase[] = data?.data ?? []

    const formatedData: Array<(IButtonGroupCell | ITextCell)[]> = rowData.map((d) => [
      { cellType: 'text', text: d.createdBy },
      { cellType: 'text', text: d.databaseName },
      { cellType: 'text', text: d.providerName },
      { cellType: 'text', text: d.adminUsername },
      { cellType: 'text', text: d.datacenterName },
      { cellType: 'text', text: d.state ? 'Active' : 'Inactive' },
      {
        cellType: 'buttonGroup',
        buttonData: [
          { color: 'success', text: 'View', onClick: () => { navigation(`${pathname}/${d._id}/view`) } },
          { color: 'info', text: 'Edit', onClick: () => { setShowAlert(true) } },
          { color: 'danger', text: 'Delete', onClick: () => { setSelectedDatabase(d) } },
        ],
      },
    ])

    return formatedData
  }, [data])

  const tableHeaderTitles = [
    'Created By',
    'DB Name',
    'Provider',
    { title: 'Admin User Name', width: 200 },
    'Data Center',
    'State',
    { title: 'Actions', width: 200 },
  ]

  const deadColumnHeaders: string[] = ['Actions']

  return (
    <AppLayout isLoading={isLoading}>
      <AlertModal
        title="Contact Support"
        description="Please contact Dbaas support to edit this record."
        isOpen={showAlert}
        okayButton={<Button onClick={() => { setShowAlert(false) }}>Okay</Button>}
      />
      <ConfirmModal
        title={`Are you sure you want to delete ${selectedDatabase?.databaseName}`}
        description={`You are about to delete the database ${selectedDatabase?.databaseName}, you can not undo this action, are you sure you want to continue?`}
        isOpen={selectedDatabase != null}
        okayButton={(
          <Button
            color="success"
            onClick={() => {
              if (selectedDatabase != null) {
                mutate(selectedDatabase?._id ?? '', {
                  onSettled: () => { setSelectedDatabase(undefined) },
                  onSuccess: () => {
                    bulmaToast.toast({
                      message: `<b>${selectedDatabase?.databaseName}</b> Database is successfully deleted!.`,
                      type: 'is-success',
                      dismissible: true,
                      duration: 6000,
                      position: 'bottom-right',
                      animate: { in: 'fadeIn', out: 'fadeOut' },
                    })
                  },
                })
              } else {
                setSelectedDatabase(undefined)
              }
            }}
            disabled={isDatabaseDeleteLoading}
          >
            Delete
          </Button>
        )}
        cancelButton={(
          <Button
            disabled={isDatabaseDeleteLoading}
            onClick={() => { setSelectedDatabase(undefined) }}
          >
            Cancel
          </Button>
        )}
      />
      <Columns>
        <Columns.Column
          desktop={{
            size: 4,
          }}
          mobile={{
            size: 12,
          }}
          className="listdbaas"
        >
          <h1 className="title is-4">
            <IconSpace>
              <FontAwesomeIcon icon={faDatabase} />
            </IconSpace>
            On Demand Databases
          </h1>
        </Columns.Column>

        <Columns.Column
          desktop={{
            textAlign: 'right',
          }}
          mobile={{
            size: 12,
            textAlign: 'justify',
          }}
        >
          <RightIcons>
            <Button onClick={() => { window.location.href = 'mailto:DTSS.DBAAS.Program.Team@disney.com' }}>
              <IconSpace>
                <FontAwesomeIcon icon={faEnvelope} />
              </IconSpace>
              Feedback
            </Button>
            <Button onClick={() => navigation(ROUTES.createDatabase)} color="success">
              <IconSpace>
                <FontAwesomeIcon icon={faPlus} />
              </IconSpace>
              Create Database
            </Button>
          </RightIcons>
        </Columns.Column>
      </Columns>
      <Table
        headers={tableHeaderTitles}
        rows={tableRowData}
        deadColumnHeaders={deadColumnHeaders}
        minWidth={1150}
      />
    </AppLayout>
  )
}
export default ListDbaas

const IconSpace = styled.span`
    padding-right: 10px;
`
const RightIcons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 15px;
`
