/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react'
import { Table as BulmaTable, Pagination } from 'react-bulma-components'
import '../../styles/DbaasTable.module.css'
import styled, { css } from 'styled-components'
import filterRows from './filterRows'
import TableHeader from '../TableHeader'
import TableBodyRowGroup from '../TableBodyRowGroup'
import { ITextCell } from '../../types'

interface HeaderOption {
    title: string;
    width?: string | number;
}
type Header = string | HeaderOption

interface SortInfo {
  columnIndex: number,
  sortType?: 'ascending' | 'descending'
}

function Table({
  headers, rows, deadColumnHeaders, minWidth,
}: {
    headers: Header[], rows: any,
    deadColumnHeaders: string[],
    minWidth?: number | string
}) {
  const [columnSearchText, setColumnSearchText] = useState({})
  const [sortColumn, setSortColumn] = useState<SortInfo | undefined>()
  const filteredRows = filterRows(rows, columnSearchText)

  const sortedRows = useMemo(() => {
    if (sortColumn && sortColumn.sortType != null) {
      const rows = [...filteredRows]
      rows.sort((rowA, rowB) => {
        const valueA = (rowA[sortColumn.columnIndex] as ITextCell).text
        const valueB = (rowB[sortColumn.columnIndex]as ITextCell).text
        if (sortColumn.sortType === 'ascending') {
          return (valueA > valueB) ? 1 : -1
        }
        return (valueA > valueB) ? -1 : 1
      })
      return rows
    }
    return filteredRows
  }, [filteredRows, sortColumn])

  return (
    <TableContainer>
      <TableWrapper minWidth={minWidth}>
        <BulmaTable
          bordered
          hoverable
          size="fullwidth"
          striped
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr>
              {headers.map((header: Header, index: number) => {
                const title = typeof header === 'string' ? header : header.title
                const width = typeof header !== 'string' ? header.width : undefined
                const headerIsNotExcluded = Boolean(deadColumnHeaders.find((header: any) => header === title)) === false
                return (
                  <TableHeader
                    isActive={sortColumn?.columnIndex === index}
                    columnSearchText={columnSearchText}
                    headerTitle={title}
                    width={width}
                    hasSearch={headerIsNotExcluded}
                    columnId={index}
                    key={index as React.Key}
                    setColumnSearchText={setColumnSearchText}
                    onSelectedSort={(sortType) => {
                      setSortColumn({ columnIndex: index, sortType })
                    }}
                  />
                )
              })}
            </tr>
          </thead>
          <tbody>
            <TableBodyRowGroup
              tableRowData={sortedRows}
              columnSearchText={columnSearchText}
            />

          </tbody>
        </BulmaTable>
        <Pagination />
      </TableWrapper>
    </TableContainer>
  )
}

const TableContainer = styled.div`
    overflow-x: auto
`

interface ITableWrapperProps {
    minWidth?: number | string
}

const TableWrapper = styled.div<ITableWrapperProps>`
    height: 587px;
    overflow-y: auto;
    ${(props) => (Boolean(props.minWidth)) && css`
        min-width: ${typeof props.minWidth === 'number' ? `${props.minWidth}px` : props.minWidth}
    `}
`
export default Table
