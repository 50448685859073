/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'

interface IPanelProps {
    title?: string;
    children: React.ReactNode
    className?: string;
    titleClassName?: string
}

const PanelContainer = styled.div`
    border: 1px solid #ddd;
    
`
const PanelTitle = styled.div`
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    border-bottom: 1px solid #ddd;
    padding: 10px 15px;
`

function Panel({
  title, children, className, titleClassName,
}: IPanelProps) {
  return (
    <PanelContainer className={className}>
      {(title != null) ? (
        <PanelTitle>
          <h4 className={titleClassName ?? 'title is-5'}>{title}</h4>
        </PanelTitle>
      ) : ''}
      {children}
    </PanelContainer>
  )
}

const PanelBody = styled.div`
    background-color: white;
    padding: 15px;
`

export { PanelBody }

export default Panel
