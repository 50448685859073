import React from 'react'
import AuthProvider from './AuthProvider'
import CredentialProvider from './CredentialProvider/CredentialProvider'
import DbaasClientProvider from './DbaasClientProvider/DbaasClientProvider'

function AppProvidersWrapper({ children }: { children: React.ReactNode }) {
  return (
    <AuthProvider>
      <CredentialProvider>
        <DbaasClientProvider>
          {children}
        </DbaasClientProvider>
      </CredentialProvider>
    </AuthProvider>
  )
}

export default AppProvidersWrapper
