import React from 'react'

function TextTooltip({ children }: { children: React.ReactNode | string }) {
  return (
    <div style={{ maxWidth: 350 }}>
      <p className="subtitle" style={{ fontSize: '.9rem', marginBottom: 0 }}>{children}</p>
    </div>
  )
}

export default TextTooltip
