import React from 'react'
import styled from 'styled-components'
import { Link, LinkProps } from 'react-router-dom'

function CustomLink(props: LinkProps) {
  const {
    to, className, target, children,
  } = props

  if (typeof to === 'string' && (to.startsWith('http') || to.startsWith('mailto:'))) {
    return <a href={to as string} className={className} target={target}>{children}</a>
  }
  return <Link {...props} />
}

const PlainTextLink = styled(CustomLink)`
    text-decoration: none;
    font-weight: 700;
`

export default PlainTextLink
