import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Columns, Container } from 'react-bulma-components'
import { Link, useLocation } from 'react-router-dom'
import { DetailsTable } from '../../components'
import ROUTES from '../../constants/ROUTES'
import { AppLayout } from '../../layouts'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { useAuth } from '../../providers/AuthProvider'
import IServiceLog from '../../types/serviceLog'

function ViewServiceLogDetails() {
  const { isAuthenticated } = useAuth()
  useFetchIsAdmin({ enabled: !isAuthenticated })

  const location = useLocation()
  const serviceLog = useMemo(() => location.state as IServiceLog | undefined, [location])

  const data = useMemo(() => {
    const details = [
      { name: 'Status', value: serviceLog?.status ?? '' },
      { name: 'Identifier', value: serviceLog?.identifier ?? '' },
      { name: 'Message', value: serviceLog?.message ?? '' },
      { name: 'Logged Date Key', value: serviceLog?.loggedDateKey.toString() ?? '' },
      { name: 'Logged Timestamp', value: serviceLog?.loggedTimestamp ?? '' },
    ]
    return details
  }, [serviceLog])
  return (
    <AppLayout>
      <Container className="is-fluid">
        <Columns multiline={false} className="mt-5">
          <Columns.Column>
            <h4 className="title is-4">
              <Link to={ROUTES.listLogs} replace>
                <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
              </Link>
              <FontAwesomeIcon className="pr-3" icon={faDatabase} />
              Logs &gt; View
              {' '}
            </h4>

          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <DetailsTable data={data} />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default ViewServiceLogDetails
