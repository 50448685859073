/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useDbaasClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

const useFetchServiceLogNames = (options?: {
  onSuccess?: ((data: AxiosResponse<string[], any>) => void) | undefined }) => {
  const { get } = useDbaasClient()

  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  const canFetch = !isLoading && isSuccess

  return useQuery(['servicelog', 'names'], () => get<string[]>('/servicelog/names'), {
    enabled: canFetch,
    onSuccess: options?.onSuccess,
  })
}

export default useFetchServiceLogNames
