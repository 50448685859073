/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Button, Container, Form, Icon, Notification,
} from 'react-bulma-components'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCaretLeft, faEye } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link } from 'react-router-dom'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import * as bulmaToast from 'bulma-toast'
import modalStyles from './Modal.module.scss'
import { Label, Panel, PanelBody } from '../../components'
import { AppLayout } from '../../layouts'
import ROUTES from '../../constants/ROUTES'
import { useCreateServer } from '../../hooks/api/server'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { useAuth } from '../../providers/AuthProvider'
import TextTooltip from '../../components/Tooltip'

const schema = yup.object({
  host: yup.string().required('is required').min(1),
  description: yup.string().required('is required').min(1),
  name: yup.string().required('is required').min(1),
  datacenterName: yup.string().required('is required').min(1),
  capacity: yup.number().positive().required('is required').min(1),
  state: yup.boolean().notRequired(),
  databaseVersion: yup.string().required('is required').min(1),
  customConnectionString: yup.string(),
  user: yup.string().required('is required')
    .matches(/^[a-zA-Z][0-9a-zA-Z_]*$/, { message: 'Must begin with alpha and only contain alphanumeric characters. Underscore is also allowed' })
    .min(4, 'Must be longer than 3 chars.')
    .max(128, 'Must be shorter than 129 characters.'),
  password: yup.string()
    .required('is required')
    .notOneOf([yup.ref('user')], 'cannot be username')
    .matches(
      // eslint-disable-next-line prefer-regex-literals
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*';\-%])[A-Za-z\d\\@&^#$!`*';\-%]{10,128}$/),
      { message: "Password should meet specified requirements. It should satisfy all of these combinations: length 10-128, [a-z], [A-Z], numbers, @, &, ^, #, $, !, `, *, ', -, %,;, cannot be username" },
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Password must match'),
  providerName: yup.string().required('is required'),
  customClientHost: yup.string(),
  pgPoolServers: yup.string(),
})

interface IFormInputs {
    host: string,
    description: string,
    datacenterName: string,
    name: string,
    capacity: number,
    state: boolean,
    databaseVersion: string,
    customConnectionString: string,
    user: string,
    password: string,
    passwordConfirmation: string,
    providerName: string,
    customClientHost: string,
    pgPoolServers: string
}

const tooltipStrongTextStyle = { outline: '#000 thin solid', display: 'table-cell', backgroundColor: '#D3D3D3' }

function CreateServerConfigure() {
  const { isAuthenticated } = useAuth()
  useFetchIsAdmin({ enabled: !isAuthenticated })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    register, control, watch, formState: { errors }, handleSubmit, setError, clearErrors,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      host: '',
      datacenterName: '',
      name: '',
      description: '',
      state: false,
      databaseVersion: '',
      customConnectionString: '',
      customClientHost: '',
      pgPoolServers: '',
      user: '',
      password: '',
      passwordConfirmation: '',
      providerName: '',
    },
  })

  const values = watch()
  const { providerName = '' } = values

  const [showErrorModal, setShowErrorModal] = useState(false)

  const { mutate, isLoading, isError } = useCreateServer()

  useEffect(() => {
    if (isError) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [isError, isLoading])

  const navigate = useNavigate()

  const onCancel = () => {
    navigate(-1)
  }

  const onSubmit = () => {
    setIsModalOpen(true)
  }

  const addServer = () => {
    mutate({
      ...values,
      ssl: false,
      serviceNowId: 'adminServerTest',
    }, {
      onSuccess: () => {
        setIsModalOpen(false)
        bulmaToast.toast({
          message: `<b>${values.name}</b> Server Configuration is successfully created.`,
          type: 'is-success',
          dismissible: true,
          duration: 6000,
          position: 'bottom-right',
          animate: { in: 'fadeIn', out: 'fadeOut' },
        })
        navigate(ROUTES.listServers, { replace: true })
      },
      onError: () => {
        setIsModalOpen(false)
      },
    })
  }

  const disableFormSubmitButton: boolean = useMemo(() => {
    if (Object.keys(errors).length > 0) {
      return true
    } if (values.host.length === 0
            || values.name.length === 0
            || values.providerName.length === 0
            || values.capacity <= 0
            || values.password.length === 0
            || values.passwordConfirmation.length === 0
            || values.description.length === 0
            || values.databaseVersion.length === 0
            || values.datacenterName.length === 0
    ) {
      return true
    }
    return false
  }, [errors, values])

  useEffect(() => {
    if (!errors.password && values.password === values.passwordConfirmation) {
      clearErrors('passwordConfirmation')
    } else if (errors.password && values.passwordConfirmation.trim() !== '') {
      setError('passwordConfirmation', { type: 'validate', message: 'Password must match' })
    }
  }, [values.password, values.passwordConfirmation, clearErrors, setError, errors.password])

  return (
    <AppLayout isLoading={isLoading}>
      <ReactModal
        ariaHideApp={false}
        className={modalStyles.modal}
        overlayClassName={modalStyles.overlay}
        isOpen={isModalOpen}
      >
        <h4 className="title is-4 mt-5">
          Are you sure you want to add
          {' '}
          {values.name}
        </h4>
        <p className="subtitle is-6 mt-5 mb-5">
          You are about to add the server
          {' '}
          {values.name}
          , you can not undo the action, are you sure you want to continue?
        </p>

        <div className="is-flex pt-5 is-flex-direction-row-reverse">
          <Button disabled={isLoading} onClick={addServer} color="success">Add Server</Button>
                    &nbsp;
                    &nbsp;
          <Button data-testid="modalCancelButton" disabled={isLoading} color="default" onClick={() => { setIsModalOpen(false) }}>Cancel</Button>
        </div>
      </ReactModal>
      <Container className="is-fluid">
        <h4 className="title is-4">
          <Link to={ROUTES.listServers} replace>
            <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
          </Link>
          <FontAwesomeIcon className="pr-3" icon={faDatabase} />
          Servers &gt; Create Server Configuration
        </h4>
        {showErrorModal && (
          <Notification color="danger">
            <button
              data-testid="nCrossBtnId"
              type="button"
              className="delete"
              onClick={() => { setShowErrorModal(false) }}
            />
            Unexpected error occurred!. Check your inputs and try again or contact admin.
          </Notification>
        )}
        <Panel title="Create Server Configuration">
          <PanelBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Field>
                <Label
                  label="Host"
                  for="host"
                  required
                  popover={(
                    <TextTooltip>
                      <TooltipTextContainer>
                        <p><b>This field value should be in following format:</b></p>
                        <div>
                          <p>If provider is MYSQL: [hostname]</p>
                          <p>Example: </p>
                          <p style={tooltipStrongTextStyle}>
                            dbaasmydbdev01.starwave.com
                          </p>
                        </div>
                        <div>
                          <p>If provider is MSSQL: </p>
                          <p> [hostname]:[portnumber];databaseName=dbaas</p>
                          <p> Example:</p>
                          <p style={tooltipStrongTextStyle}>
                            TDSSQLAGL15.mgmt.prod:1433;databaseName=dbaas
                          </p>
                        </div>
                        <div>
                          <p> If provider is PostgreSQL: </p>
                          <p>[hostname]:[portnumber]/postgres</p>
                          <p>Example:</p>
                          <p style={tooltipStrongTextStyle}>
                            qn7dsc01pgdb01.starwave.com:5432/postgres
                          </p>
                        </div>
                      </TooltipTextContainer>
                    </TextTooltip>
                                      )}
                />
                <Form.Control>
                  <Controller
                    name="host"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="host"
                        color={errors.host ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.host && <Form.Help color="danger">{errors.host.message}</Form.Help>}
              </Form.Field>
              <Form.Field>
                <Label label="Provider Name" for="providerName" required />
                <Form.Control>
                  <Controller
                    name="providerName"
                    control={control}
                    render={({ field }) => (
                      <Form.Select
                        fullwidth
                        defaultValue=""
                        id="providerName"
                        color={errors.providerName ? 'danger' : undefined}
                        {...field}
                      >
                        <option disabled={providerName.length > 0} value="" />
                        <option value="MSSQL">MSSQL</option>
                        <option value="MYSQL">MYSQL</option>
                        <option value="PostgreSQL">PostgreSQL</option>
                      </Form.Select>
                    )}
                  />
                </Form.Control>
                {errors.providerName && <Form.Help color="danger">{errors.providerName.message}</Form.Help>}
              </Form.Field>
              <Form.Field>
                <Label
                  label="Datacenter Name"
                  for="datacenterName"
                  required
                  popover={(
                    <TextTooltip>
                      A connectivity request to the database VIP can be made from anywhere within the Disney Global Network.
                    </TextTooltip>
)}
                />
                <Form.Control>
                  <Controller
                    name="datacenterName"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="datacenterName"
                        color={errors.datacenterName ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.datacenterName && <Form.Help color="danger">{errors.datacenterName.message}</Form.Help>}
              </Form.Field>
              <Form.Field>
                <Label
                  label="Name"
                  for="name"
                  required
                  popover={<TextTooltip>This field value will be used to display to dbaas users as 'Service Offering' drop down field to select in database creation page.</TextTooltip>}
                />
                <Form.Control>
                  <Controller
                    name="name"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="name"
                        color={errors.name ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.name && <Form.Help color="danger">{errors.name.message}</Form.Help>}
              </Form.Field>
              <Form.Field>
                <Label label="Description" for="description" required />
                <Form.Control>
                  <Controller
                    name="description"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="description"
                        color={errors.description ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.description && <Form.Help color="danger">{errors.description.message}</Form.Help>}
              </Form.Field>
              <Form.Field>
                <Label label="Disk Capacity (In GB)" for="capacity" required />
                <Form.Control>
                  <Controller
                    name="capacity"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="capacity"
                        color={errors.capacity ? 'danger' : undefined}
                        {...field}
                        type="number"
                      />
                    ))}
                  />
                </Form.Control>
                {errors.capacity && <Form.Help color="danger">{errors.capacity.message}</Form.Help>}
              </Form.Field>
              <Form.Field>
                <Label label="Database Version" for="databaseVersion" required />
                <Form.Control>
                  <Controller
                    name="databaseVersion"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="databaseVersion"
                        color={errors.databaseVersion ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.databaseVersion && <Form.Help color="danger">{errors.databaseVersion.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Custom Connection String" for="customConnectionString" />
                <Form.Control>
                  <Controller
                    name="customConnectionString"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="customConnectionString"
                        color={errors.customConnectionString ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.customConnectionString && <Form.Help color="danger">{errors.customConnectionString.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Custom Client Host" for="customClientHost" />
                <Form.Control>
                  <Controller
                    name="customClientHost"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="customClientHost"
                        color={errors.customClientHost ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.customClientHost && <Form.Help color="danger">{errors.customClientHost.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Custom Server Config (only used for PGSQL)" for="pgPoolServers" />
                <Form.Control>
                  <Controller
                    name="pgPoolServers"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="pgPoolServers"
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
              </Form.Field>

              <Form.Field>
                <Label
                  label="User"
                  for="user"
                  required
                  popover={(
                    <TextTooltip>
                      This is the credentials will be used by dbaas api service to do database create/drop/reset password/add user/remove user etc.
                    </TextTooltip>
)}
                />
                <Form.Control>
                  <Controller
                    name="user"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="user"
                        color={errors.user ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                </Form.Control>
                {errors.user && <Form.Help color="danger">{errors.user.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label
                  label="Password"
                  for="password"
                  required
                  popover={(
                    <TextTooltip>
                      This is the credentials will be used by dbaas api service to do database create/drop/reset password/add user/remove user etc.
                    </TextTooltip>
)}
                />
                <Form.Control>
                  <Controller
                    name="password"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        color={errors.password ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                  <Icon align="right">
                    <FontAwesomeIcon icon={faEye} color="rgb(74, 74, 74);" cursor="pointer" pointerEvents="initial" onClick={() => setShowPassword(!showPassword)} />
                  </Icon>
                </Form.Control>
                {errors.password && <Form.Help color="danger">{errors.password.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Password Confirmation" for="passwordConfirmation" required />
                <Form.Control>
                  <Controller
                    name="passwordConfirmation"
                    control={control}
                    render={(({ field }) => (
                      <Form.Input
                        id="passwordConfirmation"
                        type={showConfirmPassword ? 'text' : 'password'}
                        color={errors.passwordConfirmation ? 'danger' : undefined}
                        {...field}
                      />
                    ))}
                  />
                  <Icon align="right">
                    <FontAwesomeIcon icon={faEye} color="rgb(74, 74, 74);" cursor="pointer" pointerEvents="initial" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                  </Icon>
                </Form.Control>
                {errors.passwordConfirmation && <Form.Help color="danger">{errors.passwordConfirmation.message}</Form.Help>}
              </Form.Field>

              <Form.Field horizontal className="is-align-content-center mb-0">
                <Form.Control>
                  <label className="checkbox mt-5" htmlFor="state">
                    <span className="title is-6">State &nbsp;</span>
                    <input type="checkbox" id="state" {...register('state')} />
                                        &nbsp; Active
                  </label>
                </Form.Control>
              </Form.Field>

              <Form.Field className="is-flex is-justify-content-space-between mt-5">
                <Button color="danger" onClick={onCancel}>Cancel</Button>
                <Button type="submit" disabled={disableFormSubmitButton} color="success">Submit</Button>
              </Form.Field>
            </form>
          </PanelBody>
        </Panel>
      </Container>
    </AppLayout>
  )
}

const TooltipTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    div {
        p{
            line-height: 20px;
        }
        margin-bottom: 10px;
    }
`

export default CreateServerConfigure
