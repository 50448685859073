import styled from 'styled-components'

export const BorderBox = styled.div`
    border: 1px solid lightgray;
    padding: 2vh 1vw;
    width: 100%;
    margin-top: 30px;
    min-height: 150px;

    & > h2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    & h3 {
        margin-bottom: 10px;

        a {
            font-size: 18px;
        }
    }
`

export const LinkList = styled.ul`
    text-decoration: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
`

export const LeftContent = styled.div`
    & p {
        color: #000;
    }
    & > h1 {
        font-size: 36px;
        font-weight: 700;
    }
`

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    row-gap: 30px;
    border: 1px solid lightgray;
    padding: 2vh 1vw;
    & > h2 {
        font-size: 26px;
        font-weight: 700;
    }
    & p {
        color: #000;
    }
`

export const Spacer = styled.div`
    margin: 100px 0;
`
