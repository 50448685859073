import React from 'react'
import ReactModal from 'react-modal'
import modalStyles from './Modal.module.scss'

function AlertModal({
  isOpen,
  okayButton,
  title,
  description,
}: {
    title: string,
    description: string,
    isOpen: boolean,
    okayButton: React.ReactNode
}) {
  return (
    <ReactModal
      ariaHideApp={false}
      className={modalStyles.modal}
      overlayClassName={modalStyles.overlay}
      isOpen={isOpen}
    >
      <h4 className="title is-4 mt-5">{title}</h4>
      <p className="subtitle is-6 mt-5 mb-5">{description}</p>
      <div className="is-flex pt-5 is-flex-direction-row-reverse">
        {okayButton}
      </div>
    </ReactModal>
  )
}

export default AlertModal
