import React, { useMemo, useState } from 'react'
import { Button, Columns, Container } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { AlertModal, ConfirmModal, Table } from '../../components'
import { AppLayout } from '../../layouts'
import { IButtonGroupCell, ITextCell } from '../../types'
import ROUTES from '../../constants/ROUTES'
import IServer from '../../types/IServer'
import { useDeleteServerById, useFetchServers } from '../../hooks/api/server'

function ListServer() {
  const [showAlert, setShowAlert] = useState(false)

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const [selectedServer, setSelectedServer] = useState<IServer | undefined>()

  const { data, isFetching: isServerLoading } = useFetchServers()

  const { mutate, isLoading: isServerDeleteLoading } = useDeleteServerById()

  const isLoading = isServerLoading || isServerDeleteLoading

  const tableHeaderTitles = [
    { title: 'Host', width: '20%' },
    { title: 'Provider Name', width: '20%' },
    { title: 'Name', width: '20%' },
    { title: 'Description', width: '20%' },
    { title: 'State', width: '10%' },
    { title: 'Actions', width: 221 },
  ]

  const tableRowData = useMemo(() => {
    const rowData = data?.data ?? []

    const formatedData: Array<(IButtonGroupCell | ITextCell)[]> = rowData.map((d) => [
      { cellType: 'text', text: d.host },
      { cellType: 'text', text: d.providerName },
      { cellType: 'text', text: d.name },
      { cellType: 'text', text: d.description },
      { cellType: 'text', text: d.state ? 'Active' : 'Inactive' },
      {
        cellType: 'buttonGroup',
        buttonData: [
          { color: 'success', text: 'View', onClick: () => { navigate(`${pathname}/${d._id}/view`) } },
          { color: 'info', text: 'Edit', onClick: () => { setShowAlert(true) } },
          { color: 'danger', text: 'Delete', onClick: () => { setSelectedServer(d) } },
        ],
      },
    ])

    return formatedData
  }, [data])

  const deadColumnHeaders: string[] = ['Actions']

  const onClickCreateServerPage = () => {
    navigate(ROUTES.createServerConfigure)
  }

  return (
    <AppLayout isLoading={isLoading}>
      <AlertModal
        title="Contact Support"
        description="Please contact Dbaas support to edit this record."
        isOpen={showAlert}
        okayButton={<Button onClick={() => { setShowAlert(false) }}>Okay</Button>}
      />
      <ConfirmModal
        title={`Are you sure you want to delete ${selectedServer?.name}`}
        description={`You are about to delete the server ${selectedServer?.name}, you can not undo this action, are you sure you want to continue?`}
        isOpen={selectedServer != null}
        okayButton={(
          <Button
            color="success"
            onClick={() => {
              if (selectedServer != null) {
                mutate(selectedServer?._id ?? '', {
                  onSettled: () => { setSelectedServer(undefined) },
                  onSuccess: () => {
                    bulmaToast.toast({
                      message: `<b>${selectedServer.name}</b> Server Configuration is successfully deleted!.`,
                      type: 'is-success',
                      dismissible: true,
                      duration: 6000,
                      position: 'bottom-right',
                      animate: { in: 'fadeIn', out: 'fadeOut' },
                    })
                  },
                })
              } else {
                setSelectedServer(undefined)
              }
            }}
            disabled={isServerDeleteLoading}
          >
            Delete
          </Button>
        )}
        cancelButton={(
          <Button
            disabled={isServerDeleteLoading}
            onClick={() => { setSelectedServer(undefined) }}
          >
            Cancel
          </Button>
        )}
      />
      <Container className="is-fluid pr-0 pl-0">
        <Columns multiline={false} className="mt-5">
          <Columns.Column>
            <h4 className="title is-4">
              <FontAwesomeIcon className="pr-3" icon={faDatabase} />
              Database Servers

            </h4>
          </Columns.Column>
        </Columns>
        <Columns multiline={false}>
          <Columns.Column className="is-flex is-flex-direction-row-reverse">
            <Button color="success" onClick={onClickCreateServerPage}>
              {' '}
              <FontAwesomeIcon className="pr-3" icon={faPlus} />
              {' '}
              Create Server Configuration
              {' '}
            </Button>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Table
              headers={tableHeaderTitles}
              rows={tableRowData}
              deadColumnHeaders={deadColumnHeaders}
            />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default ListServer
