/* eslint-disable react/require-default-props */
import classNames from 'classnames'
import React from 'react'
import ReactModal from 'react-modal'
import modalStyles from './Modal.module.scss'

function ConfirmModal({
  isOpen,
  cancelButton,
  okayButton,
  title,
  description,
  isReverseActionButtonsPosition,
}: {
    title: string,
    description: string,
    isOpen: boolean,
    cancelButton: JSX.Element,
    okayButton: JSX.Element,
    isReverseActionButtonsPosition?: boolean
}) {
  return (
    <ReactModal
      ariaHideApp={false}
      className={modalStyles.modal}
      overlayClassName={modalStyles.overlay}
      isOpen={isOpen}
    >
      <h4 className="title is-4 mt-5">{title}</h4>
      <p className="subtitle is-6 mt-5 mb-5">{description}</p>
      <div className={classNames(
        'is-flex pt-5',
        { 'is-flex-direction-row-reverse': isReverseActionButtonsPosition === true },
        { 'is-justify-content-flex-end': isReverseActionButtonsPosition == null || isReverseActionButtonsPosition === false },
      )}
      >
        {okayButton}
                &nbsp;
                &nbsp;
        {cancelButton}
      </div>
    </ReactModal>
  )
}

export default ConfirmModal
