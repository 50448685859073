import { AxiosInstance } from 'axios'
import React from 'react'

interface ClientContext {
    dbaasClient: AxiosInstance
    taxonomyClient: AxiosInstance
}

const DbaasClientContext = React.createContext<ClientContext | undefined>(undefined)

export default DbaasClientContext
