import { useQuery } from 'react-query'
import { useDbaasClient } from '../../client'
import IServer from '../../../types/IServer'
import { useFetchIsAdmin } from '../admin'

const useFetchServerById = (id?: string) => {
  const { get } = useDbaasClient()

  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()

  return useQuery(['servers', id], () => get<IServer>(`/servers/${id}`), {
    enabled: !isLoading && isSuccess && id != null,
  })
}

export default useFetchServerById
