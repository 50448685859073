import { faDatabase, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Columns, Container } from 'react-bulma-components'
import { useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { AlertModal, ConfirmModal, Table } from '../../components'
import { AppLayout } from '../../layouts'
import ROUTES from '../../constants/ROUTES'
import { IButtonGroupCell, ITextCell } from '../../types'
import { useDeleteAdministratorById, useFetchAdmins } from '../../hooks/api/admin'
import IAdministrator from '../../types/admin'

function ListAdministrator() {
  const navigate = useNavigate()

  const [showAlert, setShowAlert] = useState(false)

  const { data: adminsResponse, isFetching: isAdminsLoading } = useFetchAdmins()
  const admins = adminsResponse?.data ?? []

  const tableHeaderTitles = [
    'Name',
    { title: 'Actions', width: 294 },
  ]

  const [selectedAdministrator, setSelectedAdministrator] = useState<IAdministrator | undefined>()

  const tableRowData: Array<(IButtonGroupCell | ITextCell)[]> = admins.map((admin) => [
    { cellType: 'text', text: admin.name },
    {
      cellType: 'buttonGroup',
      buttonData: [
        { color: 'info', text: 'Edit', onClick: () => { setShowAlert(true) } },
        { color: 'danger', text: 'Delete', onClick: () => { setSelectedAdministrator(admin) } },
      ],
    },
  ])

  const onClickCreateServerPage = () => {
    navigate(ROUTES.createAdministrator)
  }

  const deadColumnHeaders: string[] = ['Actions']

  const { mutate, isLoading: isAdminDeleteLoading } = useDeleteAdministratorById()

  const isLoading = isAdminsLoading || isAdminDeleteLoading

  return (
    <AppLayout isLoading={isLoading}>
      <AlertModal
        title="Contact Support"
        description="Please contact Dbaas support to edit this record."
        isOpen={showAlert}
        okayButton={<Button onClick={() => { setShowAlert(false) }}>Okay</Button>}
      />
      <ConfirmModal
        title={`Are you sure you want to delete ${selectedAdministrator?.name}`}
        description={`You are about to delete the Admin ${selectedAdministrator?.name}, you can not undo this action, are you sure you want to continue?`}
        isOpen={selectedAdministrator != null}
        okayButton={(
          <Button
            color="success"
            onClick={() => {
              if (selectedAdministrator != null) {
                mutate(selectedAdministrator?._id ?? '', {
                  onSettled: () => { setSelectedAdministrator(undefined) },
                  onSuccess: () => {
                    bulmaToast.toast({
                      message: `<b>${selectedAdministrator.name}</b> user is successfully deleted!.`,
                      type: 'is-success',
                      dismissible: true,
                      duration: 6000,
                      position: 'bottom-right',
                      animate: { in: 'fadeIn', out: 'fadeOut' },
                    })
                  },
                })
              } else {
                setSelectedAdministrator(undefined)
              }
            }}
            disabled={isAdminDeleteLoading}
          >
            Delete
          </Button>
        )}
        cancelButton={(
          <Button
            disabled={isAdminDeleteLoading}
            onClick={() => { setSelectedAdministrator(undefined) }}
          >
            Cancel
          </Button>
        )}
      />
      <Container className="is-fluid pt-4 pr-0 pl-0">
        <Columns multiline={false}>
          <Columns.Column>
            <h4 className="title is-4">
              <FontAwesomeIcon className="pr-3" icon={faDatabase} />
              Administrators
            </h4>
          </Columns.Column>
        </Columns>
        <Columns multiline={false}>
          <Columns.Column className="is-flex is-flex-direction-row-reverse">
            <Button color="success" onClick={onClickCreateServerPage}>
              {' '}
              <FontAwesomeIcon className="pr-3" icon={faPlus} />
              {' '}
              Create New Administrator
              {' '}
            </Button>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Table
              headers={tableHeaderTitles}
              rows={tableRowData}
              deadColumnHeaders={deadColumnHeaders}
            />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default ListAdministrator
