/* eslint-disable no-use-before-define */
import React, {
  ReactNode, useEffect, useMemo, useState,
} from 'react'
import { Form } from 'react-bulma-components'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import ROUTES from '../../constants/ROUTES'
import { Label, Panel, PanelBody } from '../../components'
import { AppLayout } from '../../layouts'
import TextTooltip from '../../components/Tooltip'
import useFetchDatacenter, { useFetchDataServers, useFetchProviders } from '../../hooks/api/datacenter'
import { IPrimaryFields } from '../../types/createDatabase'
import { CreateDatabaseForm, CreateOracleDatabaseForm, Taxonomy } from '../../components/CreateDatabase'

const primarySchema = yup.object({
  dataCenter: yup.string().required('is required').min(1),
  providerName: yup.string().required('is required').min(1),
  serviceOffering: yup.string().required('is required').min(1),
})

function CreateDatabase() {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const [taxonomy, setTaxonomy] = useState<string | undefined>()
  const [isTaxonomyLoading, setIsTaxonomyLoading] = useState(false)

  const editCapability = useMemo(() => {
    const isEdit = pathname === ROUTES.createDatabaseEdit
    if (isEdit) {
      const isValidInitialEditValue = primarySchema.isValidSync(state)
      const canEditCreate = isValidInitialEditValue && isEdit
      return { canEditCreate }
    }
    return { canEditCreate: false }
  }, [pathname, state, navigate])

  const { canEditCreate } = editCapability

  useEffect(() => {
    if (!canEditCreate) {
      navigate(ROUTES.createDatabase, { replace: true })
    }
  }, [canEditCreate])

  const initialValues = useMemo(() => {
    if (!canEditCreate) {
      return { dataCenter: '', providerName: '' }
    }
    const tempState = state as Partial<IPrimaryFields>
    return {
      dataCenter: tempState.dataCenter,
      providerName: tempState.providerName,
    }
  }, [state, canEditCreate])

  const {
    watch, formState: { isValid }, setValue, register,
  } = useForm<IPrimaryFields>({
    resolver: yupResolver(primarySchema),
    mode: 'onChange',
    defaultValues: initialValues,
  })
  const values = watch()
  const { data: dataCenterResponse, isFetching: isDatacenterLoading } = useFetchDatacenter()
  const dataCenters = dataCenterResponse?.data ?? []

  const {
    data: providersResponse,
    isFetching: isProviderLoading,
  } = useFetchProviders(values.dataCenter, {
    onSuccess: (response) => {
      if (response.data.length > 0 && !canEditCreate) {
        setValue('providerName', response.data[0], { shouldValidate: true })
      }
    },
  })
  const providers = providersResponse?.data ?? []

  const {
    data: serversResponse,
    isFetching: isDataServersLoading,
  } = useFetchDataServers(values.dataCenter, values.providerName, {
    onSuccess: (response) => {
      if (response.data.length > 0 && !canEditCreate) {
        setValue('serviceOffering', response.data[0]._id, { shouldValidate: true })
      }
    },
    enabled: !isProviderLoading,
  })
  const servers = serversResponse?.data ?? []

  const isLoading = isTaxonomyLoading
    || isDataServersLoading || isProviderLoading || isDatacenterLoading

  return (
    <AppLayout isLoading={isLoading}>
      <PageLayout pageName="On Demand Database > Create">
        <Panel title="Create Database">
          <PanelBody>
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Form.Field>
                <Label
                  label="Datacenter"
                  for="dataCenter"
                  required
                  popover={(
                    <TextTooltip>
                      A connectivity request to the database VIP can be made
                      from anywhere within the Disney Global Network.
                    </TextTooltip>
                  )}
                />
                <Form.Control>
                  <div className="select is-fullwidth">
                    <select id="dataCenter" {...register('dataCenter')}>
                      <option value="" disabled>Select a Data Center</option>
                      {dataCenters.map((dataCenter, i) => <option key={`${i + dataCenter}`} value={dataCenter}>{dataCenter}</option>)}
                    </select>
                  </div>
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Label label="Provider" required />
                <Form.Control>
                  <div className="select is-fullwidth">
                    <select disabled={providers.length === 0} id="providerName" {...register('providerName')}>
                      {providers.map((provider) => (
                        <option
                          key={provider}
                          value={provider}
                        >
                          {provider}
                        </option>
                      ))}
                    </select>
                  </div>
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Label label="Service offering" required />
                <Form.Control>
                  <div className="select is-fullwidth">
                    <select disabled={servers.length === 0} id="serviceOffering" {...register('serviceOffering')}>
                      {servers.map((server) => (
                        <option
                          key={server._id}
                          value={server._id}
                        >
                          {server.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Form.Control>
              </Form.Field>
              <Taxonomy onLoading={setIsTaxonomyLoading} onSelected={setTaxonomy} />

              {values.providerName === 'ORACLE'
                ? <CreateOracleDatabaseForm />
                : (
                  <CreateDatabaseForm
                    isPrimaryFormValid={isValid}
                    taxonomyId={taxonomy}
                    {...values}
                  />
                )}
            </form>
          </PanelBody>
        </Panel>
      </PageLayout>
    </AppLayout>
  )
}

export default CreateDatabase
interface IPagelayout {
  children: ReactNode,
  pageName: string
}

function PageLayout({ children, pageName }: IPagelayout) {
  return (
    <div className="container is-fluid">
      <h4 className="title is-5">
        <Link to={ROUTES.listDatabases} replace>
          <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
        </Link>
        <FontAwesomeIcon className="pr-3" icon={faDatabase} />
        {pageName}
      </h4>
      {children}
    </div>
  )
}
