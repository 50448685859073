import React, { useMemo, useState } from 'react'
import { Columns, Container, Form } from 'react-bulma-components'
import { useNavigate } from 'react-router-dom'
import { Label, Table } from '../../components'
import ROUTES from '../../constants/ROUTES'
import { useFetchServiceLogNames, useFetchServiceLogsByName } from '../../hooks/api/serviceLog'
import { AppLayout } from '../../layouts'
import { IButtonGroupCell, ITextCell } from '../../types'

function ListServiceLogs() {
  const navigate = useNavigate()
  const [selectedServiceLogName, setSelectedServiceLogName] = useState('')

  const {
    data: serviceLogNamesResponse,
    isFetching: isServiceLogNamesLoading,
  } = useFetchServiceLogNames({
    onSuccess: ({ data }) => {
      if (data.length > 0) {
        setSelectedServiceLogName(data[0])
      }
    },
  })

  const serviceLogNames = serviceLogNamesResponse?.data ?? []

  const {
    data: serviceLogsResponse,
    isFetching: isServerlogsLoading,
  } = useFetchServiceLogsByName(selectedServiceLogName)

  const tableHeaderTitles = [
    { title: 'ID' },
    { title: 'Log Message', width: '45%' },
    { title: 'Status' },
    { title: 'Identifier' },
    { title: 'Log Date' },
    { title: 'Log Timestamp', width: '12%' },
    { title: 'View', width: '8%' },
  ]

  const tableRowData = useMemo(() => {
    const rowData = serviceLogsResponse?.data ?? []

    const formatedData: Array<(IButtonGroupCell | ITextCell)[]> = rowData.map((d) => [
      { cellType: 'text', text: d._id },
      { cellType: 'text', text: d.message },
      { cellType: 'text', text: d.status },
      { cellType: 'text', text: d.identifier },
      { cellType: 'text', text: d.loggedDateKey.toString() },
      { cellType: 'text', text: d.loggedTimestamp },
      {
        cellType: 'buttonGroup',
        buttonData: [
          { color: 'success', text: 'View', onClick: () => { navigate(ROUTES.viewLogDetails, { state: d }) } },
        ],
      },
    ])

    return formatedData
  }, [serviceLogsResponse])

  const deadColumnHeaders: string[] = ['View']

  const isLoading = isServerlogsLoading || isServiceLogNamesLoading

  return (
    <AppLayout isLoading={isLoading}>
      <Container className="is-fluid pt-0 pr-0 pl-0">
        <Columns multiline={false} className="mt-5">
          <Columns.Column>
            <Form.Field>
              <Label label="Select service log name" for="serviceLogName" />
              <Form.Control>
                <Form.Select
                  data-testid="serviceLogName"
                  fullwidth
                  defaultValue=""
                  id="serviceLogName"
                  value={selectedServiceLogName}
                  onChange={({ target }) => { setSelectedServiceLogName(target.value) }}
                >
                  {serviceLogNames.map((serviceLogName) => (
                    <option
                      key={serviceLogName}
                      value={serviceLogName}
                    >
                      {serviceLogName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column size={12}>
            <Table
              headers={tableHeaderTitles}
              rows={tableRowData}
              deadColumnHeaders={deadColumnHeaders}
              minWidth={1150}
            />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default ListServiceLogs
