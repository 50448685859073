import React from 'react'
import { AppLoader } from '../../components'
import { useFetchIsAdmin } from '../../hooks/api/admin'

function Redirect() {
  const { isLoading } = useFetchIsAdmin()
  return (
    <div data-testid="redirect-page">
      <AppLoader isLoading={isLoading} />
    </div>
  )
}

export default Redirect
