import useFetchDatacenter from './useFetchDatacenter'
import useFetchDataServers from './useFetchDataServers'
import useFetchProviders from './useFetchProviders'

export {
  useFetchProviders,
  useFetchDataServers,
}

export default useFetchDatacenter
