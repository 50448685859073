/* eslint-disable no-use-before-define */
import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Columns, Container, Notification } from 'react-bulma-components'
import { Link, useParams } from 'react-router-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import styled from 'styled-components'
import { AppLoader, DetailsTable } from '../../components'
import ROUTES from '../../constants/ROUTES'
import { useFetchDatabaseById, useFetchDatabaseStats } from '../../hooks/api/databases'
import { useFetchTaxonomyDetailsById } from '../../hooks/api/taxonomoy'

interface INavigationParams {
    databaseID: string
}

function ViewDatabaseDetails() {
  const { databaseID } = useParams<keyof INavigationParams>()

  const {
    data: databaseResponse,
    isFetching: isDatabaseLoading, isError: isDatabaseError,
  } = useFetchDatabaseById(databaseID)

  const database = databaseResponse?.data

  const {
    data: taxonomyDetailsResponse,
    isFetching: isTaxonomyLoading, isError: isTaxonomyError,
  } = useFetchTaxonomyDetailsById(database?.taxonomyId, { enabled: database != null })

  const taxonomyData = taxonomyDetailsResponse?.data

  const {
    data: databaseStatsResponse,
    isFetching: isDatabaseStatsLoading, isError: isDatabaseStatsError,
  } = useFetchDatabaseStats(database?._id, { enabled: database != null })

  const databaseStats = databaseStatsResponse?.data

  const isLoading = isDatabaseLoading || isTaxonomyLoading || isDatabaseStatsLoading

  const seriesData = useMemo(() => {
    const data: Array<(string | number)[]> = []
    databaseStats?.forEach((state) => {
      data.push([state.timestamp, Number(state.total_size_mb)])
    })
    return data
  }, [databaseStats])

  const data = useMemo(() => {
    const details = [
      { name: 'ID', value: database?._id ?? '' },
      { name: 'Provider Name', value: database?.providerName ?? '' },
      { name: 'Database Name', value: database?.databaseName ?? '' },
      { name: 'Size', value: (database?.size != null) ? database.size.toString() : '' },
      { name: 'Connection String', value: database?.connectionString ?? '' },
      { name: 'Created By', value: database?.createdBy ?? '' },
      { name: 'Owners', value: database?.owners.join(' ') ?? '' },
      { name: 'Server ID', value: database?.serverId ?? '' },
      { name: 'Taxonomy ID', value: database?.taxonomyId ?? '' },
      { name: 'Segment', value: taxonomyData?.businessUnitGroupName ?? '' },
      { name: 'Business Unit', value: taxonomyData?.businessName ?? '' },
      { name: 'Affiliate Name', value: taxonomyData?.affiliateName ?? '' },
      { name: 'Admin Username', value: database?.adminUsername.toUpperCase() ?? '' },
      { name: 'Created At', value: database?.createdAt ?? '' },
      { name: 'Updated At', value: database?.updatedAt ?? '' },
    ]
    return details
  }, [database, taxonomyData])

  const options: Highcharts.Options = useMemo(() => ({
    title: {
      text: 'Usage History',
      align: 'left',
    },
    chart: {
      renderTo: 'container',
      zoomType: 'xy',
      reflow: true,
      type: 'line',
      events: {},
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        type: 'datetime',
        formatter: (obj: Highcharts.AxisLabelsFormatterContextObject) => {
          const d = new Date(seriesData[Number(obj.value)][0])
          return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`
        },
      },
    },
    yAxis: {
      labels: {
        format: '{value:.0f}',
      },
      title: {
        text: 'MB',
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
    },
    series: [{
      name: 'Storage Usage',
      data: seriesData,
      type: 'line',
    }],
  }), [seriesData])

  const getErrorNotification = useMemo(() => {
    if (isDatabaseError) {
      return (
        <Notification color="danger">
          Failed to fatch Database information. Please contact support.
        </Notification>
      )
    }
    if (isDatabaseStatsError) {
      return (
        <Notification color="danger">
          Failed to fatch Database stats. Please contact support.
        </Notification>
      )
    }
    if (isTaxonomyError) {
      return (
        <Notification color="danger">
          Failed to fatch Taxonomy details. Please contact support.
        </Notification>
      )
    }
    return ''
  }, [isDatabaseError, isDatabaseStatsError, isTaxonomyError])

  return (
    <div>
      <AppLoader isLoading={isLoading} />
      <Container>
        <Columns multiline={false} className="mt-5">
          <Columns.Column>
            <h4 className="title is-4">
              <Link to={ROUTES.listDatabases} replace>
                <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
              </Link>
              <FontAwesomeIcon className="pr-3" icon={faDatabase} />
              Databases &gt; View Databases
              {' '}
              {database?.databaseName != null ? `${database?.databaseName} : ` : ''}
            </h4>
            {getErrorNotification}
          </Columns.Column>
        </Columns>
        <Columns flexDirection="column">
          <Columns.Column>
            <DetailsTable data={data} />
          </Columns.Column>
          <Columns.Column>
            <HighchartsReactContainer>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
              />
            </HighchartsReactContainer>
          </Columns.Column>
        </Columns>
      </Container>

    </div>
  )
}

const HighchartsReactContainer = styled.div`
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 14px 14px;
    background: white;
    border-radius: 3px;
    border: 1px solid #eeeeee;
`

export default ViewDatabaseDetails
