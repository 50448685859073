import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { useDbaasClient } from '../../client'

interface IServer {
    _id: string,
    name: string,
    host: string,
    providerName: string,
    state: true,
    databaseVersion: string,
    updatedAt: string,
    createdAt: string
}

const useFetchDataServers = (dataCenter: string, provider: string, options?: { enabled: boolean,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   onSuccess?: ((data: AxiosResponse<IServer[], any>) => void) | undefined }) => {
  const { get } = useDbaasClient()

  return useQuery(
    ['datacenters',
      dataCenter,
      'providers',
      provider],
    () => get<IServer[]>(`/datacenters/${dataCenter}/providers/${provider}/servers`),
    {
      enabled: dataCenter.length > 0 && provider.length > 0 && options?.enabled,
      onSuccess: options?.onSuccess,
    },
  )
}

export default useFetchDataServers
