/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import {
  Button, Container, Form, Notification,
} from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import * as bulmaToast from 'bulma-toast'
import { Label, Panel, PanelBody } from '../../components'
import { AppLayout } from '../../layouts'
import ROUTES from '../../constants/ROUTES'
import { useCreateAdministrator } from '../../hooks/api/admin'

const schema = yup.object({
  name: yup.string().required('is required').min(3),
})
interface IFormInputs {
    name: string
}

function CreateAdministrator() {
  const navigate = useNavigate()

  const {
    isError, mutate, isLoading, reset,
  } = useCreateAdministrator()

  const {
    register, watch, formState: { errors, isValid }, handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  })

  const values = watch()

  const onSubmit = () => {
    if (!isLoading) {
      mutate(values, {
        onSuccess: () => {
          bulmaToast.toast({
            message: `<b>${values.name}</b> user is successfully created!.`,
            type: 'is-success',
            dismissible: true,
            duration: 6000,
            position: 'bottom-right',
            animate: { in: 'fadeIn', out: 'fadeOut' },
          })
          navigate(ROUTES.listAdministrator)
        },
      })
    }
  }

  return (
    <AppLayout isLoading={isLoading}>
      <Container className="is-fluid">
        <h4 className="title is-4">
          <Link to={ROUTES.listAdministrator} replace>
            <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
          </Link>
          <FontAwesomeIcon className="pr-3" icon={faDatabase} />
          Administrators &gt; Create
        </h4>
        {isError && (
          <Notification color="danger">
            <button
              data-testid="nCrossBtnId"
              type="button"
              className="delete"
              onClick={() => { reset() }}
            />
            Unexpected error occurred!. Check your input and try again or contact admin.
          </Notification>
        )}
        <Panel title="New Administrator">
          <PanelBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Form.Field>
                <Label label="Username" for="name" required />
                <Form.Control>
                  <input
                    id="name"
                    data-testid="name"
                    className={classNames('input', { 'is-danger': errors.name != null })}
                    {...register('name')}
                  />
                </Form.Control>
                <Form.Help color="danger">{errors.name != null ? errors.name.message : ''}</Form.Help>
              </Form.Field>
              <Form.Field className="is-flex is-justify-content-space-between mt-5">
                <Button disabled={!isValid} type="submit" color="success" onClick={onSubmit}>Save</Button>
              </Form.Field>
            </form>
          </PanelBody>
        </Panel>
      </Container>
    </AppLayout>
  )
}

export default CreateAdministrator
